import BaseCrudRepository from '@/repositories/BaseCrudRepository';
import OrganizationMember from '@/repositories/data/OrganizationMember';
import OrganizationMemberId from '@/repositories/data/OrganizationMemberId';
import HttpClient from '@/util/http/HttpClient';

export default class OrganizationMemberRepository extends BaseCrudRepository<
    OrganizationMember,
    OrganizationMember,
    OrganizationMemberId
> {
    constructor(client: HttpClient) {
        super(client, 'api/organization-members/', 'CUSTOMERS', ['organizationId', 'userId']);
    }

    protected formatIntrospectUrl(create: boolean, id: OrganizationMemberId, parentId?: any): string {
        return 'introspect';
    }
}
