
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import FileRepository from '@/repositories/FileRepository';
import FileUpload from '@/repositories/data/FileUpload';
import { BehaviorSubject, from, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import Resource from '@/repositories/Resource';

@Component
export default class FileUploader extends Vue {
    @Inject()
    private readonly fileRepository!: FileRepository;

    @Prop({ required: false, type: String, default: '' })
    private readonly id!: string;

    @Prop({ required: false, type: Boolean, default: false })
    private readonly required!: boolean;

    @Prop({ required: false, type: Number, default: null })
    private readonly value!: number | null;

    @Prop({ required: false, type: String, default: '' })
    private readonly label!: string;

    private file: File | null = null;
    private fileUpload: FileUpload | null = null;
    private loading = true;
    private fileLoadSubscription = new Subscription();
    private fileUploadSubscription = new Subscription();
    private valueSubject = new BehaviorSubject<number | null>(null);

    public created(): void {
        this.valueSubject.next(this.value);
        this.fileLoadSubscription = this.valueSubject
            .pipe(
                switchMap((id) =>
                    id !== null ? this.fileRepository.getFile(id) : from([Resource.success<FileUpload>(null)]),
                ),
            )
            .subscribe((fileUploadResource) => {
                if (fileUploadResource.isSuccess) {
                    this.fileUpload = fileUploadResource.data;
                }
                this.loading = fileUploadResource.isLoading;
            });
    }

    public beforeDestroy(): void {
        this.fileLoadSubscription.unsubscribe();
        this.fileUploadSubscription.unsubscribe();
    }

    @Watch('value')
    public onValueChanged(value: number | null): void {
        this.valueSubject.next(value);
    }

    @Watch('file')
    public onFileChanged(file: File | null): void {
        if (file === null && this.fileUpload === null) {
            this.$emit('input', null);
        }
        if (file !== null) {
            this.loading = true;
            this.fileUploadSubscription.unsubscribe();
            this.fileUploadSubscription = this.fileRepository.uploadFile(file).subscribe((fileUploadResource) => {
                if (fileUploadResource.isSuccess) {
                    const fileUpload = fileUploadResource.data;
                    this.$emit('input', fileUpload ? fileUpload.id : null);
                    this.fileUpload = fileUpload;
                } else if (fileUploadResource.isError) {
                    this.$emit('error', fileUploadResource.error);
                }
                this.loading = fileUploadResource.isLoading;
            });
        }
    }

    public onDelete(): void {
        this.loading = true;
        this.file = null;
        this.fileUpload = null;
        this.$emit('input', null);
        this.loading = false;
    }

    public get rules(): ((value: any) => boolean | string)[] {
        return this.required ? [(value) => Boolean(value)] : [];
    }
}
