import BaseCrudRepository from '@/repositories/BaseCrudRepository';
import SubscriptionConsumer from '@/repositories/data/SubscriptionConsumer';
import SubscriptionConsumerId from '@/repositories/data/SubscriptionConsumerId';
import HttpClient from '@/util/http/HttpClient';

export default class SubscriptionConsumerRepository extends BaseCrudRepository<
    SubscriptionConsumer,
    SubscriptionConsumer,
    SubscriptionConsumerId
> {
    constructor(client: HttpClient) {
        super(client, 'api/subscription-consumers', 'SUBSCRIPTIONS', ['subscriptionId', 'userId']);
    }

    protected formatIntrospectUrl(create: boolean, id: SubscriptionConsumerId, parentId?: any): string {
        if (parentId === undefined) {
            throw new Error('Parent ID required for introspection');
        }
        return parentId + '/introspect';
    }
}
