import HttpClient from '@/util/http/HttpClient';
import { Observable } from 'rxjs';
import Resource from '@/repositories/Resource';
import LoginResponse from '@/repositories/data/LoginResponse';
import ApiWrapper from '@/repositories/data/ApiWrapper';
import networkBoundResource from '@/repositories/networkBoundResource';
import unwrapApiWrapper from '@/repositories/unwrapApiWrapper';
import { map } from 'rxjs/operators';
import User from '@/repositories/data/User';

export interface LoginProvider {
    url: string;
    compact: boolean;
}

export interface LoginProviderMap {
    [key: string]: LoginProvider;
}

export default class AuthRepository {
    protected readonly client: HttpClient;
    protected readonly baseUrl: string;

    constructor(client: HttpClient) {
        this.client = client;
        this.baseUrl = process.env.VUE_APP_API_ENDPOINT as string;
    }

    public login(email: string, password: string, challenge: string): Observable<Resource<LoginResponse>> {
        const body = {
            email,
            password,
            loginChallenge: challenge,
        };
        const observable = this.client.post<ApiWrapper<any>>('auth/login', body, { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable).pipe(map((t) => new LoginResponse(t!))));
    }

    public secondFactor(token: string): Observable<Resource<LoginResponse>> {
        const body = {
            token,
        };
        const observable = this.client.post<ApiWrapper<any>>('auth/login/second-factor', body, {
            baseUrl: this.baseUrl,
        });
        return networkBoundResource(unwrapApiWrapper(observable).pipe(map((t) => new LoginResponse(t!))));
    }

    public signup(
        firstName: string,
        surname: string,
        email: string,
        password: string,
        confirmPassword: string,
    ): Observable<Resource<User>> {
        const body = {
            firstName,
            surname,
            email,
            password,
            confirmPassword,
        };
        const observable = this.client.post<ApiWrapper<User>>('auth/signup', body, { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public verify(code: string): Observable<Resource<{}>> {
        const body = {
            code,
        };
        const observable = this.client.post<ApiWrapper<{}>>('auth/signup/verify', body, { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public requestPasswordResetToken(email: string): Observable<Resource<{}>> {
        const body = {
            email,
        };
        const observable = this.client.post<ApiWrapper<{}>>('auth/forgot-password/request', body, {
            baseUrl: this.baseUrl,
        });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public resetPassword(code: string, password: string, confirmPassword: string): Observable<Resource<{}>> {
        const body = {
            code,
            password,
            confirmPassword,
        };
        const observable = this.client.post<ApiWrapper<{}>>('auth/forgot-password/reset', body, {
            baseUrl: this.baseUrl,
        });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public get nextUri(): string {
        return this.baseUrl + '/auth/login/next';
    }

    public getLoginProviders(challenge: string): LoginProviderMap {
        return {
            microsoft: {
                url:
                    this.baseUrl +
                    '/delegate/oauth2/authorize/microsoft?login_challenge=' +
                    encodeURIComponent(challenge),
                compact: false,
            },
            google: {
                url:
                    this.baseUrl + '/delegate/oauth2/authorize/google?login_challenge=' + encodeURIComponent(challenge),
                compact: false,
            },
        };
    }
}
