export default class AuthStore {
    private readonly _prefix: string;
    private readonly _statePath: string;
    private readonly _tokenPath: string;

    public constructor(prefix = 'oauth2', statePath = 'states', tokenPath = 'token') {
        this._prefix = prefix;
        this._statePath = statePath;
        this._tokenPath = tokenPath;
    }

    public getState(id: string): string | null {
        return localStorage.getItem(this._prefix + '.' + this._statePath + '.' + id);
    }

    public setState(id: string, state: string): void {
        localStorage.setItem(this._prefix + '.' + this._statePath + '.' + id, state);
    }

    public removeState(id: string): void {
        localStorage.removeItem(this._prefix + '.' + this._statePath + '.' + id);
    }

    public removeStaleStates(): void {
        const now = Date.now() / 1000;
        const statesToClear: string[] = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key != null && key.startsWith(this._prefix + '.' + this._statePath)) {
                const data = localStorage.getItem(key);
                if (data != null) {
                    const state = JSON.parse(data);
                    if (!state.expires_at || state.expires_at <= now) {
                        statesToClear.push(key);
                    }
                }
            }
        }
        for (const key of statesToClear) {
            localStorage.removeItem(key);
        }
    }

    public getToken(): string | null {
        return localStorage.getItem(this._prefix + '.' + this._tokenPath);
    }

    public setToken(token: string): void {
        localStorage.setItem(this._prefix + '.' + this._tokenPath, token);
    }

    public removeToken(): void {
        localStorage.removeItem(this._prefix + '.' + this._tokenPath);
    }
}
