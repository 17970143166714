import BaseCrudRepository from '@/repositories/BaseCrudRepository';
import HttpClient from '@/util/http/HttpClient';
import Subscription from '@/repositories/data/Subscription';

export default class SubscriptionRepository extends BaseCrudRepository<Subscription, Subscription, number> {
    public constructor(client: HttpClient) {
        super(client, 'api/subscriptions/', 'SUBSCRIPTIONS');
    }

    public fieldReferralMap(): { [key: string]: (field: any) => string } {
        return {
            organizationId: function (id) {
                return '/organization/' + id;
            },
        };
    }

    public downloadExport(date: string) {
        this.client
            .getRaw<any>('export/' + date, { baseUrl: this.baseUrl, responseType: 'blob' })
            .subscribe((response) => {
                const disposition = response.headers['content-disposition'];
                let filename = 'abonnementen-' + date + '.xlsx';
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }
}
