import HttpClient from '@/util/http/HttpClient';
import OrganizationRepository from '@/repositories/OrganizationRepository';
import GlobalState from '@/util/GlobalState';
import FileRepository from '@/repositories/FileRepository';
import AuthManager from '@/util/auth/AuthManager';
import AuthRepository from '@/repositories/AuthRepository';
import AuthStore from '@/util/auth/AuthStore';
import UserRepository from '@/repositories/UserRepository';
import SubscriptionRepository from '@/repositories/SubscriptionRepository';
import SubscriptionConsumerRepository from '@/repositories/SubscriptionConsumerRepository';
import OrganizationInviteRepository from '@/repositories/OrganizationInviteRepository';
import OrganizationMemberRepository from '@/repositories/OrganizationMemberRepository';
import PublicOrganizationInvitationRepository from '@/repositories/PublicOrganizationInvitationRepository';
import OrganizationGroupRepository from '@/repositories/OrganizationGroupRepository';
import SubscriptionMassRepository from '@/repositories/SubscriptionMassRepository';
import DashboardOrganizationRepository from '@/repositories/DashboardOrganizationRepository';

const httpClient = new HttpClient({});
const authStore = new AuthStore();
export const userRepository = new UserRepository(httpClient);
export const organizationRepository = new OrganizationRepository(httpClient);
export const organizationInviteRepository = new OrganizationInviteRepository(httpClient);
export const organizationGroupRepository = new OrganizationGroupRepository(httpClient);
export const organizationMemberRepository = new OrganizationMemberRepository(httpClient);
export const fileRepository = new FileRepository(httpClient);
export const authRepository = new AuthRepository(httpClient);
export const dashboardOrganizationRepository = new DashboardOrganizationRepository(httpClient);
export const subscriptionRepository = new SubscriptionRepository(httpClient);
export const subscriptionMassRepository = new SubscriptionMassRepository(httpClient);
export const subscriptionConsumerRepository = new SubscriptionConsumerRepository(httpClient);
export const publicOrganizationInvitationRepository = new PublicOrganizationInvitationRepository(httpClient);
export const authManager = new AuthManager(
    process.env.VUE_APP_AUTH_ENDPOINT!,
    process.env.VUE_APP_AUTH_CLIENT_ID!,
    process.env.VUE_APP_AUTH_LOGOUT_URI!,
    httpClient,
    authStore,
);
export const globalState = new GlobalState(authManager, userRepository);
