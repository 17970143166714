import HttpClient from '@/util/http/HttpClient';
import { Observable } from 'rxjs';
import Resource from '@/repositories/Resource';
import networkBoundResource from '@/repositories/networkBoundResource';
import unwrapApiWrapper from '@/repositories/unwrapApiWrapper';
import ApiWrapper from '@/repositories/data/ApiWrapper';

export default class PublicOrganizationInvitationRepository {
    private readonly client: HttpClient;
    private readonly baseUrl: string;

    constructor(client: HttpClient) {
        this.client = client;
        this.baseUrl = process.env.VUE_APP_API_ENDPOINT + '/api/invitations';
    }

    public accept(token: string): Observable<Resource<string>> {
        const observable = this.client.post<ApiWrapper<string>>('/' + token, {}, { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public decline(token: string): Observable<Resource<void>> {
        const observable = this.client.get<ApiWrapper<void>>(token + '/decline', { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public getOrganizationNameForToken(token: string): Observable<Resource<string>> {
        const observable = this.client.get<ApiWrapper<string>>(token + '/relationships/organization-name', {
            baseUrl: this.baseUrl,
        });
        return networkBoundResource(unwrapApiWrapper(observable));
    }
}
