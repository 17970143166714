
import { Component, Inject, Vue } from 'vue-property-decorator';
import GeneralScaffold from '@/components/scaffolds/GeneralScaffold.vue';
import PagedDataTable from '@/components/PagedDataTable.vue';
import UserRepository from '@/repositories/UserRepository';

@Component({
    components: { GeneralScaffold, PagedDataTable },
})
export default class UserListScreen extends Vue {
    @Inject()
    private readonly userRepository!: UserRepository;

    public get headers(): any[] {
        return [
            {
                text: this.$t('general.actions'),
                value: 'action',
                sortable: false,
            },
            {
                text: this.$t('crud.user.name'),
                align: 'left',
                value: 'name',
                sortable: true,
            },
            {
                text: this.$t('crud.user.email'),
                align: 'left',
                value: 'email',
                sortable: true,
            },
        ];
    }
}
