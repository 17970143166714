import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import FormModelForm from '@/components/form/FormModelForm.vue';

Vue.config.productionTip = false;

Vue.component('FormModelForm', FormModelForm);

new Vue({
    router,
    i18n,
    // @ts-ignore
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
