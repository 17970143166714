
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import ToolbarInnerScaffold from './scaffolds/ToolbarInnerScaffold.vue';
import BaseCrudRepository, { SortDirection } from '@/repositories/BaseCrudRepository';
import { BehaviorSubject, Subscription } from 'rxjs';
import Page from '@/repositories/data/Page';
import { map, switchMap } from 'rxjs/operators';
import GlobalState from '@/util/GlobalState';

interface Options {
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
}

const defaultOptions: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
};

@Component({
    components: {
        ToolbarInnerScaffold,
    },
})
export default class DashboardDataTable extends Vue {
    public loading = true;
    public displayError = false;
    public tableOptions: Options = { ...defaultOptions };

    @Prop({ type: String, required: true })
    private readonly formRoute!: string;

    @Prop({ type: Array, required: true })
    private readonly headers!: any[];

    @Prop({ type: String, required: true })
    private readonly title!: string;

    @Prop({ type: Object, required: true })
    private readonly repository!: BaseCrudRepository<any, any, any>;

    @Inject()
    private globalState!: GlobalState;

    private tableOptionsSubject = new BehaviorSubject<Options>({ ...defaultOptions });

    private pageSubscription = new Subscription();
    private pageSubject = new BehaviorSubject<Page<any>>({
        content: [],
        total: 0,
        pageable: {
            page: 0,
            size: 1,
        },
    });

    public created(): void {
        this.pageSubscription = this.tableOptionsSubject
            .pipe(
                map((options) => ({
                    page: options.page - 1,
                    size: options.itemsPerPage,
                    sort: options.sortBy.map((t, i) => ({
                        property: t,
                        direction: options.sortDesc[i] ? SortDirection.DESC : SortDirection.ASC,
                    })),
                })),
                switchMap((pageable) => this.repository.findPage(pageable)),
            )
            .subscribe((pageResource) => {
                this.loading = pageResource.isLoading;
                this.displayError = pageResource.isError;
                if (pageResource.isSuccess && pageResource.data) {
                    this.pageSubject.next(pageResource.data);
                } else if (pageResource.isError) {
                    this.globalState.generalErrorHandling(pageResource.error);
                }
            });
    }

    public beforeDestroy(): void {
        this.pageSubscription.unsubscribe();
    }

    public get items(): any[] {
        return this.pageSubject.value.content;
    }

    public get itemsTotal(): number {
        return this.pageSubject.value.total;
    }

    @Watch('tableOptions', { deep: true })
    private onTableOptionsChanged(options: Options): void {
        this.tableOptionsSubject.next(options);
    }

    private editItem(item: any): void {
        this.$router.push({ name: this.formRoute, params: { id: item.id } });
    }
}
