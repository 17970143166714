
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormModelField, FormModelFieldOption } from '@/repositories/data/FormModel';
import BaseCrudRepository from '@/repositories/BaseCrudRepository';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OnBeforeDestroy, OnCreated } from '@/util/vue/vueCallbacks';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component
export default class ServerBasedEnumFormField extends Vue implements OnCreated, OnBeforeDestroy {
    public search: string = '';
    public selectableOptions: FormModelFieldOption[] = [];
    public loading: boolean = true;
    public internalValue: any = null;

    @Prop({ type: String, required: true })
    private readonly id!: boolean;

    @Prop({ type: Number, required: false, default: 0 })
    private readonly parentId!: number;

    @Prop({ type: String, required: true })
    private readonly label!: boolean;

    @Prop({ type: Object, required: true })
    private readonly field!: FormModelField;

    @Prop({ required: true })
    private readonly entityId!: any;

    @Prop({ type: Object, required: true })
    private readonly entityRepository!: BaseCrudRepository<any, any, any>;

    @Prop({ type: Boolean, required: false, default: false })
    private readonly hidden!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    private readonly disabled!: boolean;

    @Prop({ required: false, default: null })
    private readonly rules!: any;

    @Prop({ required: false, default: null })
    private readonly errorMessages!: any;

    private searchSubject = new BehaviorSubject('');
    private searchSubscription = new Subscription();
    private didInitialize = false;

    private get optionsType(): string {
        return this.field.type.substring('enum-server:'.length);
    }

    created(): void {
        this.initialize();
    }

    beforeDestroy(): void {
        this.searchSubscription.unsubscribe();
    }

    @Watch('search', { immediate: true })
    public onSearchChanged(value: string): void {
        this.searchSubject.next(value);
    }

    @Watch('internalValue')
    public onInternalValueChanged(value: any) {
        if (this.didInitialize) {
            this.field.value = value;
        }
    }

    @Watch('entityId')
    public onEntityIdChanged(): void {
        this.initialize();
    }

    @Watch('entityRepository')
    public onEntityRepositoryChanged(): void {
        this.initialize();
    }

    @Watch('field')
    public onFieldChanged(): void {
        this.initialize();
    }

    private initialize(): void {
        this.didInitialize = false;
        this.searchSubscription.unsubscribe();
        this.searchSubscription = this.searchSubject
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((search) =>
                    this.entityRepository.introspectOptions(
                        this.optionsType,
                        this.parentId,
                        this.entityId,
                        this.field.value,
                        {
                            page: 0,
                            size: 25,
                            sort: [],
                            filter: search,
                        },
                    ),
                ),
            )
            .subscribe((resource) => {
                if (resource.data) {
                    const page = resource.data;
                    this.selectableOptions = page.content;
                    if (!this.didInitialize) {
                        this.didInitialize = true;
                        this.internalValue = '' + this.field.value;
                    }
                }
                this.loading = resource.isLoading;
            });
    }
}
