import BaseCrudRepository from '@/repositories/BaseCrudRepository';
import OrganizationInvite from '@/repositories/data/OrganizationInvite';
import HttpClient from '@/util/http/HttpClient';

export default class OrganizationInviteRepository extends BaseCrudRepository<
    OrganizationInvite,
    OrganizationInvite,
    string
> {
    constructor(client: HttpClient) {
        super(client, 'api/organization-invitations/', 'CUSTOMERS');
    }

    protected formatIntrospectUrl(create: boolean, id: string, parentId?: any): string {
        if (parentId === undefined) {
            throw new Error('Parent ID required for introspection');
        }
        return parentId + '/introspect';
    }
}
