export default class LoginResponse {
    private readonly loginState: string;

    constructor(data: any) {
        this.loginState = data.loginState;
    }

    public get requiresSecondFactor(): boolean {
        return this.loginState === 'RequiresSecondFactor';
    }

    public get loginComplete(): boolean {
        return this.loginState === 'LoginComplete';
    }
}
