
import { Component, Inject, Vue } from 'vue-property-decorator';
import GeneralScaffold from '@/components/scaffolds/GeneralScaffold.vue';
import FormModelDisplay from '@/components/form/FormModelDisplay.vue';
import OrganizationRepository from '@/repositories/OrganizationRepository';
import SubscriptionRepository from '@/repositories/SubscriptionRepository';
import OrganizationMemberRepository from '@/repositories/OrganizationMemberRepository';
import OrganizationInviteRepository from '@/repositories/OrganizationInviteRepository';
import SubscriptionConsumerRepository from '@/repositories/SubscriptionConsumerRepository';
import OrganizationGroupRepository from '@/repositories/OrganizationGroupRepository';
import EmbeddedForm from '@/util/form/EmbeddedForm';

@Component({
    components: {
        GeneralScaffold,
        FormModelDisplay,
    },
})
export default class OrganizationFormScreen extends Vue {
    @Inject()
    private readonly organizationRepository!: OrganizationRepository;
    @Inject()
    private readonly subscriptionRepository!: SubscriptionRepository;
    @Inject()
    private readonly organizationMemberRepository!: OrganizationMemberRepository;
    @Inject()
    private readonly organizationInviteRepository!: OrganizationInviteRepository;
    @Inject()
    private readonly organizationGroupRepository!: OrganizationGroupRepository;
    @Inject()
    private readonly subscriptionConsumerRepository!: SubscriptionConsumerRepository;

    private get embeds(): EmbeddedForm[] {
        return [
            {
                relationship: 'members',
                parentRefKey: 'organizationId',
                i18nGroup: 'organizationMember',
                entityRepository: this.organizationMemberRepository,
                searchLabel: this.$t('crud.organizationMember.name').toString(),
                columns: [
                    {
                        text: this.$t('general.actions'),
                        value: 'action',
                        sortable: false,
                    },
                    {
                        text: this.$t('crud.organizationMember.name'),
                        value: 'name',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.organizationMember.label'),
                        value: 'label',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.organizationMember.admin'),
                        value: 'verboseAdmin',
                        sortable: true,
                    },
                ],
                embeds: [
                    {
                        relationship: 'subscriptions',
                        parentRefKey: 'id.userId',
                        i18nGroup: 'subscription',
                        entityRepository: this.subscriptionRepository,
                        columns: [
                            {
                                text: this.$t('general.actions'),
                                value: 'action',
                                sortable: false,
                            },
                            {
                                text: this.$t('crud.subscription.label'),
                                value: 'label',
                                sortable: true,
                            },
                            {
                                text: this.$t('crud.subscription.beginTimestamp'),
                                value: 'beginTimestamp',
                                sortable: true,
                            },
                            {
                                text: this.$t('crud.subscription.endTimestamp'),
                                value: 'endTimestamp',
                                sortable: true,
                            },
                        ],
                    },
                ],
            },
            {
                relationship: 'invites',
                parentRefKey: 'organizationId',
                i18nGroup: 'organizationInvite',
                entityRepository: this.organizationInviteRepository,
                columns: [
                    {
                        text: this.$t('general.actions'),
                        value: 'action',
                        sortable: false,
                    },
                    {
                        text: this.$t('crud.organizationInvite.email'),
                        value: 'email',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.organizationInvite.sendDate'),
                        value: 'sendDate',
                        sortable: true,
                    },
                ],
            },
            {
                relationship: 'groups',
                parentRefKey: 'organizationId',
                i18nGroup: 'organizationGroup',
                entityRepository: this.organizationGroupRepository,
                columns: [
                    {
                        text: this.$t('general.actions'),
                        value: 'action',
                        sortable: false,
                    },
                    {
                        text: this.$t('crud.organizationGroup.azureId'),
                        value: 'azureId',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.organizationGroup.label'),
                        value: 'label',
                        sortable: true,
                    },
                ],
            },
            {
                relationship: 'subscriptions',
                parentRefKey: 'organizationId',
                i18nGroup: 'subscription',
                entityRepository: this.subscriptionRepository,
                searchLabel: this.$t('crud.subscription.label').toString(),
                columns: [
                    {
                        text: this.$t('general.actions'),
                        value: 'action',
                        sortable: false,
                    },
                    {
                        text: this.$t('crud.subscription.label'),
                        value: 'label',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.subscription.beginTimestamp'),
                        value: 'beginTimestamp',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.subscription.endTimestamp'),
                        value: 'endTimestamp',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.subscription.consumerCount'),
                        value: 'verboseConsumerCount',
                        sortable: true,
                    },
                ],
                embeds: [
                    {
                        relationship: 'consumers',
                        parentRefKey: 'subscriptionId',
                        i18nGroup: 'subscriptionConsumer',
                        entityRepository: this.subscriptionConsumerRepository,
                        columns: [
                            {
                                text: this.$t('general.actions'),
                                value: 'action',
                                sortable: false,
                            },
                            {
                                text: this.$t('crud.subscriptionConsumer.name'),
                                value: 'name',
                                sortable: false,
                            },
                            {
                                text: this.$t('crud.subscriptionConsumer.label'),
                                value: 'label',
                                sortable: false,
                            },
                            {
                                text: this.$t('crud.subscriptionConsumer.manager'),
                                value: 'verboseManager',
                                sortable: true,
                            },
                        ],
                    },
                ],
            },
        ];
    }
}
