import HttpClient from '@/util/http/HttpClient';
import Page from '@/repositories/data/Page';
import ApiWrapper from '@/repositories/data/ApiWrapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import FormModel, { FormModelFieldOption } from '@/repositories/data/FormModel';
import Resource from '@/repositories/Resource';
import unwrapApiWrapper from '@/repositories/unwrapApiWrapper';
import networkBoundResource from '@/repositories/networkBoundResource';

export enum SortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export interface Sort {
    property: string;
    direction: SortDirection;
}

export interface Pageable {
    page: number;
    size: number;
    sort: Sort[];
    filter?: any;
    additionalParams?: any;
}

export default abstract class BaseCrudRepository<T, F extends Object, I> {
    protected readonly client: HttpClient;
    protected readonly baseUrl: string;
    protected readonly domain: string;

    // (In case of compound ids) The names of the properties of the Id, id url will be constructed using this order
    protected readonly compoundIdOrder: string[];

    protected constructor(client: HttpClient, basePath: string, domain: string, compoundIdOrder?: string[]) {
        this.client = client;
        this.baseUrl = process.env.VUE_APP_API_ENDPOINT + '/' + basePath;
        this.compoundIdOrder = compoundIdOrder ? compoundIdOrder : [];
        this.domain = domain;
    }

    public findPage(pageable: Pageable): Observable<Resource<Page<T>>> {
        const params: any = this.buildPageableParams(pageable);
        const observable = this.client.get<ApiWrapper<Page<T>>>('', {
            baseUrl: this.baseUrl,
            params,
        });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public findOne(id: I): Observable<Resource<T>> {
        const observable = this.client.get<ApiWrapper<T>>(this.formatId(id), { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public create(user: F): Observable<Resource<T>> {
        const observable = this.client.post<ApiWrapper<T>>('', user, { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public update(user: F): Observable<Resource<T>> {
        const observable = this.client.put<ApiWrapper<T>>('', user, { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public delete(id: I): Observable<Resource<void>> {
        const observable = this.client.delete<any>(this.formatId(id), { baseUrl: this.baseUrl });
        return networkBoundResource(observable);
    }

    public introspect(create: boolean, id: I, parentId?: any): Observable<Resource<FormModel>> {
        const options = {
            baseUrl: this.baseUrl,
            params: {
                mode: create ? 'create' : 'edit',
            },
        };
        const url = this.formatIntrospectUrl(create, id, parentId);
        const observable = this.client.get<ApiWrapper<any[]>>(url, options);
        return networkBoundResource(unwrapApiWrapper(observable).pipe(map((t) => new FormModel(t!))));
    }

    public introspectOptions(
        type: string,
        parentId: any,
        id: I | null,
        value: any | null,
        pageable: Pageable,
    ): Observable<Resource<Page<FormModelFieldOption>>> {
        const params: any = this.buildPageableParams(pageable);
        const url = this.formatIntrospectUrl(id === null, id || (0 as any), parentId) + '/options/' + type;
        if (id !== null) {
            params.id = this.formatId(id);
        }
        if (value !== null) {
            params.value = value;
        }
        const observable = this.client.get<ApiWrapper<Page<FormModelFieldOption>>>(url, {
            baseUrl: this.baseUrl,
            params,
        });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public findRelatedPage(
        id: I,
        relationship: string,
        pageable: Pageable,
        search?: string,
    ): Observable<Resource<Page<T>>> {
        const params: any = this.buildPageableParams(pageable, search);
        const observable = this.client.get<ApiWrapper<Page<T>>>(this.formatId(id) + '/relationships/' + relationship, {
            baseUrl: this.baseUrl,
            params,
        });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    protected formatId(id: I): string {
        if (this.compoundIdOrder !== null && this.compoundIdOrder.length > 0) {
            let url = '';
            for (const key of this.compoundIdOrder) {
                // @ts-ignore
                if (id.hasOwnProperty(key)) {
                    // @ts-ignore
                    url += '/' + id[key];
                }
            }
            //remove initial slash
            return url.substr(1);
        } else {
            return '' + id;
        }
    }

    public fieldReferralMap(): { [key: string]: (field: any) => string } {
        return {};
    }

    protected formatIntrospectUrl(create: boolean, id: I, parentId?: any): string {
        return this.formatId(id) + '/introspect';
    }

    protected buildPageableParams(pageable: Pageable, search?: string) {
        const params: any = {
            page: pageable.page,
            size: pageable.size,
            sort: pageable.sort.map((t) => t.property + ',' + t.direction).join(','),
            q: search ? search : '',
        };
        if (pageable.filter) {
            params.q = pageable.filter;
        }
        if (pageable.additionalParams) {
            for (const param in pageable.additionalParams) {
                params[param] = pageable.additionalParams[param];
            }
        }
        return params;
    }

    public get authDomain() {
        return this.domain;
    }
}
