
import { Component, Inject, Vue } from 'vue-property-decorator';
import GeneralScaffold from '@/components/scaffolds/GeneralScaffold.vue';
import FormModelDisplay from '@/components/form/FormModelDisplay.vue';
import UserRepository from '@/repositories/UserRepository';
import OrganizationMemberRepository from '@/repositories/OrganizationMemberRepository';
import EmbeddedForm from '@/util/form/EmbeddedForm';
import SubscriptionRepository from '@/repositories/SubscriptionRepository';

@Component({
    components: {
        GeneralScaffold,
        FormModelDisplay,
    },
})
export default class UserFormScreen extends Vue {
    @Inject()
    private readonly userRepository!: UserRepository;
    @Inject()
    private readonly organizationMemberRepository!: OrganizationMemberRepository;
    @Inject()
    private readonly subscriptionRepository!: SubscriptionRepository;

    public get embeds(): EmbeddedForm[] {
        return [
            {
                relationship: 'organizations',
                parentRefKey: 'userId',
                i18nGroup: 'organizationMember',
                entityRepository: this.organizationMemberRepository,
                columns: [
                    {
                        text: this.$t('general.actions'),
                        value: 'action',
                        sortable: false,
                    },
                    {
                        text: this.$t('crud.organizationMember.label'),
                        value: 'label',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.organizationMember.organizationId'),
                        value: 'organizationName',
                        sortable: true,
                    },
                ],
                embeds: [
                    {
                        relationship: 'subscriptions',
                        parentRefKey: 'id.userId',
                        i18nGroup: 'subscription',
                        entityRepository: this.subscriptionRepository,
                        columns: [
                            {
                                text: this.$t('general.actions'),
                                value: 'action',
                                sortable: false,
                            },
                            {
                                text: this.$t('crud.subscription.label'),
                                value: 'label',
                                sortable: true,
                            },
                            {
                                text: this.$t('crud.subscription.beginTimestamp'),
                                value: 'beginTimestamp',
                                sortable: true,
                            },
                            {
                                text: this.$t('crud.subscription.endTimestamp'),
                                value: 'endTimestamp',
                                sortable: true,
                            },
                        ],
                    },
                ],
            },
            {
                relationship: 'subscriptions',
                parentRefKey: 'userId',
                i18nGroup: 'subscription',
                entityRepository: this.subscriptionRepository,
                columns: [
                    {
                        text: this.$t('general.actions'),
                        value: 'action',
                        sortable: false,
                    },
                    {
                        text: this.$t('crud.subscription.label'),
                        value: 'label',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.subscription.beginTimestamp'),
                        value: 'beginTimestamp',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.subscription.endTimestamp'),
                        value: 'endTimestamp',
                        sortable: true,
                    },
                ],
            },
        ];
    }
}
