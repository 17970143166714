import BaseCrudRepository from '@/repositories/BaseCrudRepository';
import User from '@/repositories/data/User';
import Resource from '@/repositories/Resource';
import ApiWrapper from '@/repositories/data/ApiWrapper';
import networkBoundResource from '@/repositories/networkBoundResource';
import unwrapApiWrapper from '@/repositories/unwrapApiWrapper';
import { Observable } from 'rxjs';
import HttpClient from '@/util/http/HttpClient';

export default class UserRepository extends BaseCrudRepository<User, User, number> {
    public constructor(client: HttpClient) {
        super(client, 'api/users/', 'USERS');
    }

    public findCurrent(): Observable<Resource<User>> {
        const observable = this.client.get<ApiWrapper<User>>('current', { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }
}
