
import { Component, Inject, Model, Vue } from 'vue-property-decorator';
import SubscriptionRepository from '@/repositories/SubscriptionRepository';

@Component
export default class ExportDialog extends Vue {
    @Model('input', { type: Boolean })
    public readonly show!: boolean;

    @Inject()
    public readonly subscriptionRepository!: SubscriptionRepository;

    public date: string | null = null;

    public onCancel() {
        this.$emit('input', false);
    }

    public onConfirm() {
        const repository = this.subscriptionRepository;
        const date = this.date;
        if (date) {
            this.$emit('input', false);
            setTimeout(() => {
                repository.downloadExport(date);
            });
        }
    }
}
