import AuthState from '@/util/auth/AuthState';
import AuthStore from '@/util/auth/AuthStore';
import HttpClient from '@/util/http/HttpClient';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import Resource from '@/repositories/Resource';
import networkBoundResource from '@/repositories/networkBoundResource';

interface AuthToken {
    access_token?: string;
    expires_in?: number;
    expires_at?: number;
    id_token?: string;
    refresh_token?: string;
    scope?: string;
    token_type?: string;
}

export default class AuthManager {
    private readonly endpoint: string;
    private readonly clientId: string;
    private readonly logoutUri: string;
    private readonly httpClient: HttpClient;
    private readonly store: AuthStore;
    private _token: AuthToken | null;

    public constructor(
        endpoint: string,
        clientId: string,
        logoutUri: string,
        httpClient: HttpClient,
        store: AuthStore,
    ) {
        this.endpoint = endpoint;
        this.clientId = clientId;
        this.logoutUri = logoutUri;
        this.httpClient = httpClient;
        this.store = store;
        const data = store.getToken();
        this._token = data ? JSON.parse(data) : null;
        if (this._token) {
            this.httpClient.clientDefaults((defaults) => {
                defaults.headers.common = {
                    Authentication: 'Bearer ' + this._token?.access_token,
                };
                return defaults;
            });
        }
        store.removeStaleStates();
    }

    public startRedirectLogin(): void {
        this.clearToken();
        const authState = new AuthState(null, this.store);
        authState
            .generateAuthParams()
            .pipe(
                map((params) =>
                    Object.keys(params)
                        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
                        .join('&'),
                ),
            )
            .subscribe((params) => {
                const query = '?client_id=' + encodeURIComponent(this.clientId) + '&' + params;
                window.location.href = this.endpoint + '/oauth2/auth' + query;
            });
    }

    public startRedirectLogout(): void {
        const targetToken = this._token != null ? this._token.id_token : null;
        const query = targetToken
            ? '?id_token_hint=' +
              encodeURIComponent(targetToken) +
              '&state=logout&post_logout_redirect_uri=' +
              this.logoutUri
            : '';
        this.clearToken();
        window.location.href = this.endpoint + '/oauth2/sessions/logout' + query;
    }

    public validateLogin(code: string | null, state: string | null): Observable<Resource<AuthToken>> {
        const authState = new AuthState(state, this.store);
        if (!authState.validateLoginParams(code, state)) {
            return networkBoundResource(throwError(new Error('Invalid login parameters')));
        }
        const codeVerifier = authState.codeVerifier;
        const form = new FormData();
        form.append('grant_type', 'authorization_code');
        form.append('code', code!);
        form.append('client_id', this.clientId);
        form.append('code_verifier', codeVerifier);
        return networkBoundResource(
            this.httpClient.post<AuthToken>(this.endpoint + '/oauth2/token', form).pipe(
                map((token) => {
                    if (token.expires_in != undefined) {
                        const now = Date.now() / 1000;
                        token.expires_at = now + token.expires_in;
                    }
                    return token;
                }),
                tap((token) => {
                    this.store.setToken(JSON.stringify(token));
                    this._token = token;
                    this.httpClient.clientDefaults((defaults) => {
                        defaults.headers.common = {
                            Authentication: 'Bearer ' + token.access_token,
                        };
                        return defaults;
                    });
                    authState.clearFromStorage();
                }),
            ),
        );
    }

    public get token(): AuthToken | null {
        return this._token;
    }

    public get expired(): boolean {
        let result = true;
        if (this._token && this._token.expires_at) {
            const now = Date.now() / 1000;
            result = this._token.expires_at <= now;
        }
        return result;
    }

    private clearToken(): void {
        this.store.removeToken();
        this._token = null;
        this.httpClient.clientDefaults((defaults) => {
            defaults.headers.common = {};
            return defaults;
        });
    }
}
