import axios, { AxiosDefaults, AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface HttpClientOptions {
    baseUrl?: string;
    headers?: any;
}

export interface HttpRequestOptions {
    baseUrl?: string;
    params?: any;
    headers?: any;
    responseType?: ResponseType;
}

function convertRequestOptions(options?: HttpRequestOptions): AxiosRequestConfig {
    if (!options) {
        return {};
    }
    const { baseUrl, ...sameOptions } = options;
    const config: AxiosRequestConfig = sameOptions;
    if (baseUrl) {
        config.baseURL = baseUrl;
    }
    return config;
}

export default class HttpClient {
    private readonly client: AxiosInstance;

    public constructor(options: HttpClientOptions) {
        const config = convertRequestOptions(options);
        config.withCredentials = true;
        this.client = axios.create(config);
    }

    public clientDefaults(configuration: (defaults: AxiosDefaults) => AxiosDefaults): void {
        const defaults = this.client.defaults;
        defaults.withCredentials = true;
        this.client.defaults = configuration(defaults);
    }

    public getRaw<T>(url: string, options?: HttpRequestOptions): Observable<AxiosResponse<T>> {
        const config = convertRequestOptions(options);
        return from(this.client.get(url, config));
    }

    public get<T>(url: string, options?: HttpRequestOptions): Observable<T> {
        return this.getRaw<T>(url, options).pipe(map((response) => response.data));
    }

    public headRaw<T>(url: string, options?: HttpRequestOptions): Observable<AxiosResponse<T>> {
        const config = convertRequestOptions(options);
        return from(this.client.head(url, config));
    }

    public head<T>(url: string, options?: HttpRequestOptions): Observable<T> {
        return this.headRaw<T>(url, options).pipe(map((response) => response.data));
    }

    public deleteRaw<T>(url: string, options?: HttpRequestOptions): Observable<AxiosResponse<T>> {
        const config = convertRequestOptions(options);
        return from(this.client.delete(url, config));
    }

    public delete<T>(url: string, options?: HttpRequestOptions): Observable<T> {
        return this.deleteRaw<T>(url, options).pipe(map((response) => response.data));
    }

    public postRaw<T>(url: string, data?: any, options?: HttpRequestOptions): Observable<AxiosResponse<T>> {
        const config = convertRequestOptions(options);
        return from(this.client.post(url, data, config));
    }

    public post<T>(url: string, data?: any, options?: HttpRequestOptions): Observable<T> {
        return this.postRaw<T>(url, data, options).pipe(map((response) => response.data));
    }

    public putRaw<T>(url: string, data?: any, options?: HttpRequestOptions): Observable<AxiosResponse<T>> {
        const config = convertRequestOptions(options);
        return from(this.client.put(url, data, config));
    }

    public put<T>(url: string, data?: any, options?: HttpRequestOptions): Observable<T> {
        return this.putRaw<T>(url, data, options).pipe(map((response) => response.data));
    }

    public patchRaw<T>(url: string, data?: any, options?: HttpRequestOptions): Observable<AxiosResponse<T>> {
        const config = convertRequestOptions(options);
        return from(this.client.patch(url, data, config));
    }

    public patch<T>(url: string, data?: any, options?: HttpRequestOptions): Observable<T> {
        return this.patchRaw<T>(url, data, options).pipe(map((response) => response.data));
    }
}
