
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import ToolbarInnerScaffold from './scaffolds/ToolbarInnerScaffold.vue';
import BaseCrudRepository, { Pageable, SortDirection } from '@/repositories/BaseCrudRepository';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import Page from '@/repositories/data/Page';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import GlobalState from '@/util/GlobalState';

interface Options {
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
}

function createDefaultOptions(): Options {
    return {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
    };
}

@Component({
    components: {
        ToolbarInnerScaffold,
    },
})
export default class PagedDataTable extends Vue {
    public loading = true;
    public search = '';
    public booleanSearches: string[] = [];
    public displayError = false;
    public deleteDialog = false;
    public deleting = false;
    public tableOptions: Options = createDefaultOptions();
    public footerProps = {
        itemsPerPageOptions: [20, 50, 100],
    };

    @Prop({ type: String, required: true })
    private readonly i18nGroup!: string;

    @Prop({ type: String, required: true })
    private readonly formRoute!: string;

    @Prop({ type: Array, required: true })
    private readonly headers!: any[];

    @Prop({ required: true, type: Object })
    private readonly repository!: BaseCrudRepository<any, any, any>;

    @Prop({ type: Boolean, required: false, default: false })
    private readonly enableSearch!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    private readonly showAddButton!: boolean;

    @Prop({ type: String, required: false, default: '' })
    private readonly title!: string;

    @Prop({ type: Object, required: false, default: () => {} })
    private readonly additionalParameters!: any;

    @Inject()
    private globalState!: GlobalState;

    private deleteTarget: any = null;
    private deleteSubscription = new Subscription();
    private tableOptionsSubject = new BehaviorSubject<Options>(this.tableOptions);
    private searchSubject = new BehaviorSubject<string>('');
    private additionalParametersSubject = new BehaviorSubject<string[]>([]);
    private pageSubscription = new Subscription();
    private pageSubject = new BehaviorSubject<Page<any>>({
        content: [],
        total: 0,
        pageable: {
            page: 0,
            size: 1,
        },
    });

    public created(): void {
        this.updateFromRouteQuery();
        this.pageSubscription = combineLatest([
            this.tableOptionsSubject,
            this.additionalParametersSubject,
            this.searchSubject.pipe(debounceTime(300)),
        ])
            .pipe(
                map(([options, additionalParameters, search]): Pageable => {
                    return {
                        page: options.page - 1,
                        size: options.itemsPerPage,
                        sort: options.sortBy.map((t, i) => ({
                            property: t,
                            direction: options.sortDesc[i] ? SortDirection.DESC : SortDirection.ASC,
                        })),
                        filter: search,
                        additionalParams: additionalParameters,
                    };
                }),
                switchMap((pageable) => this.repository.findPage(pageable)),
            )
            .subscribe((pageResource) => {
                this.loading = pageResource.isLoading;
                this.displayError = pageResource.isError;
                if (pageResource.isSuccess && pageResource.data) {
                    this.pageSubject.next(pageResource.data);
                } else if (pageResource.isError) {
                    this.globalState.generalErrorHandling(pageResource.error);
                }
            });
    }

    public beforeDestroy(): void {
        this.deleteSubscription.unsubscribe();
        this.pageSubscription.unsubscribe();
    }

    public get items(): any[] {
        return this.pageSubject.value.content;
    }

    public get itemsTotal(): number {
        return this.pageSubject.value.total;
    }

    @Watch('tableOptions', { deep: true })
    private onTableOptionsChanged(options: Options): void {
        this.tableOptionsSubject.next(options);
        this.mergeRouteQuery({
            pi: options.page.toString(10),
            ps: options.itemsPerPage.toString(10),
        });
    }

    @Watch('search', { deep: true })
    private onSearchChanged(search: string): void {
        this.searchSubject.next(search);
    }

    @Watch('additionalParameters', { deep: true })
    private onAdditionalParametersChanged(additionalParameters: any): void {
        this.additionalParametersSubject.next(additionalParameters);
    }

    @Watch('$route')
    public onRouteChanged() {
        this.updateFromRouteQuery();
    }

    private onNew(): void {
        this.$router.push({ name: this.formRoute, params: { id: 'new' } });
    }

    private editItem(item: any): void {
        this.$router.push({ name: this.formRoute, params: { id: item.id } });
    }

    private deleteItem(item: any): void {
        this.deleteTarget = item;
        this.deleteDialog = true;
    }

    private confirmDelete(): void {
        if (this.deleteTarget) {
            this.deleteSubscription.unsubscribe();
            this.deleting = true;
            this.deleteSubscription = this.repository.delete(this.deleteTarget.id).subscribe((resource) => {
                this.deleting = resource.isLoading;
                this.displayError = resource.isError;
                this.deleteDialog = false;
                this.tableOptionsSubject.next(this.tableOptions);
            });
        }
    }

    private cancelDelete(): void {
        this.deleteSubscription.unsubscribe();
        this.deleting = false;
        this.deleteDialog = false;
        this.deleteTarget = null;
    }

    private submit(): void {
        this.$emit('search', this.search);
    }

    private addBooleanFilter(field: string, value: boolean) {
        if (value) {
            if (!this.booleanSearches.includes(field)) {
                this.booleanSearches.push(field);
            }
        } else {
            if (this.booleanSearches.includes(field)) {
                this.booleanSearches = this.booleanSearches.filter(function (item) {
                    return item !== field;
                });
            }
        }
    }

    private updateFromRouteQuery() {
        if (this.$route.query.hasOwnProperty('pi')) {
            this.tableOptions.page =
                typeof this.$route.query.pi === 'string' && /^[-+]?(\d+)$/.test(this.$route.query.pi)
                    ? parseInt(this.$route.query.pi)
                    : 1;
        }
        if (this.$route.query.hasOwnProperty('ps')) {
            this.tableOptions.itemsPerPage =
                typeof this.$route.query.ps === 'string' && /^[-+]?(\d+)$/.test(this.$route.query.ps)
                    ? parseInt(this.$route.query.ps)
                    : 20;
        }
    }

    private mergeRouteQuery(query: any) {
        const nextQuery = {
            ...this.$route.query,
            ...query,
        };
        Object.keys(query).forEach((key) => {
            if (query[key] === null) {
                delete nextQuery[key];
            }
        });
        this.$router
            .replace({
                ...this.$router.currentRoute,
                name: this.$router.currentRoute.name!,
                query: nextQuery,
            })
            .catch(() => {
                // ignored
            });
    }
}
