
import GeneralScaffold from '@/components/scaffolds/GeneralScaffold.vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import GlobalState from '@/util/GlobalState';
import ToolbarInnerScaffold from '@/components/scaffolds/ToolbarInnerScaffold.vue';
import PublicOrganizationInvitationRepository from '@/repositories/PublicOrganizationInvitationRepository';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    components: {
        ToolbarInnerScaffold,
        GeneralScaffold,
    },
})
export default class InvitationAcceptScreen extends Vue {
    @Inject()
    private readonly globalState!: GlobalState;
    @Inject()
    private readonly publicOrganizationInvitationRepository!: PublicOrganizationInvitationRepository;

    private token: string | null = null;
    private declineLoading: boolean = false;
    private acceptLoading: boolean = false;

    private organizationNameSubscription = new Subscription();
    private organizationNameSubject = new BehaviorSubject('');

    public created(): void {
        if (this.$route.query && this.$route.query.hasOwnProperty('token')) {
            this.token = this.$route.query.token as string;
        } else {
            this.token = this.globalState.readInvitationToken();
        }

        if (this.token != null) {
            this.organizationNameSubscription = this.publicOrganizationInvitationRepository
                .getOrganizationNameForToken(this.token)
                .subscribe((resource) => {
                    if (resource.isSuccess) {
                        if (resource.data !== null) {
                            this.organizationNameSubject.next(resource.data);
                        }
                    } else if (resource.isError) {
                        this.globalState.toast(this.$t('general.communicationError').toString());
                    }
                });
        } else {
            this.$router.push({ name: 'home' });
        }
    }

    public beforeDestroy(): void {
        this.organizationNameSubscription.unsubscribe();
    }

    public accept() {
        this.acceptLoading = true;
        if (this.token !== null) {
            if (this.globalState.isUserLoaded) {
                this.publicOrganizationInvitationRepository.accept(this.token).subscribe((resource) => {
                    if (resource.isSuccess) {
                        this.globalState.clearInvitationToken();
                        this.$router.push({ name: 'home' });
                    } else if (resource.isError) {
                        this.globalState.toast(this.$t('general.communicationError').toString());
                    }
                });
            } else {
                this.globalState.saveInvitationToken(this.token);
                this.$router.push('/login');
            }
        }
    }

    public decline() {
        this.declineLoading = true;
        if (this.token !== null) {
            this.publicOrganizationInvitationRepository.decline(this.token).subscribe();
        }
    }

    public get organizationName(): string {
        return this.organizationNameSubject.value;
    }
}
