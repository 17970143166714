import FormModel, { FormModelField } from '@/repositories/data/FormModel';

enum ReferenceMode {
    FieldValue,
    TriggeredValue,
    TargetValue,
    Constant,
}

function popReferencePath(path: string | null): { head: string | null; tail: string | null } {
    if (path === null) {
        return { head: null, tail: null };
    } else if (!path.includes('.')) {
        return { head: path, tail: null };
    } else {
        const elements = path.split('.');
        const head = elements.shift();
        return { head: head ? head : null, tail: elements.length > 0 ? elements.join('.') : null };
    }
}

class FormReference {
    private readonly referenceMode: ReferenceMode;
    private readonly reference: string | null;

    constructor(data: any) {
        this.referenceMode = ReferenceMode[data.referenceMode as keyof typeof ReferenceMode];
        this.reference = data.reference;
    }

    private evaluateFurther(value: any, path: string | null): any {
        if (path === null) {
            return value;
        } else {
            const splitPath = popReferencePath(path);
            if (splitPath.head !== null && value.hasOwnProperty(splitPath.head)) {
                return this.evaluateFurther(value[splitPath.head], splitPath.tail);
            }
        }
        return null;
    }

    public evaluateValue(triggeredValue: any, targetValue: any, formModel: FormModel, type?: string): any {
        if (this.referenceMode === ReferenceMode.Constant) {
            return this.reference;
        } else if (this.referenceMode === ReferenceMode.TriggeredValue) {
            return this.evaluateFurther(triggeredValue, this.reference);
        } else if (this.referenceMode === ReferenceMode.TargetValue) {
            return this.evaluateFurther(targetValue, this.reference);
        } else if (this.referenceMode === ReferenceMode.FieldValue) {
            const path = popReferencePath(this.reference);
            for (const group of formModel.groups) {
                for (const field of group.fields) {
                    if (field.name === path.head) {
                        let value = field.value;
                        if (field.options !== null && type !== COLLECTION_EXPRESSION_TYPE) {
                            value = field.options[value];
                        }
                        return this.evaluateFurther(value, path.tail);
                    }
                }
            }
        }
        return null;
    }
}

interface FormInteractionExpression {
    type: string;
    evaluate(triggeredValue: any, targetValue: any, formModel: FormModel): boolean;
}

enum LogicalOperand {
    AND,
    OR,
}

const LOGICAL_EXPRESSION_TYPE = 'logical';
class LogicalExpression implements FormInteractionExpression {
    private readonly lhs: FormInteractionExpression;
    private readonly rhs: FormInteractionExpression;
    private readonly operand: LogicalOperand;

    constructor(data: any) {
        if (data.lhs.type === LOGICAL_EXPRESSION_TYPE) {
            this.lhs = new LogicalExpression(data.lhs);
        } else {
            this.lhs = new ComparisonExpression(data.lhs);
        }
        if (data.rhs.type === LOGICAL_EXPRESSION_TYPE) {
            this.rhs = new LogicalExpression(data.rhs);
        } else {
            this.rhs = new ComparisonExpression(data.rhs);
        }
        this.operand = LogicalOperand[data.operand as keyof typeof LogicalOperand];
    }

    public get type(): string {
        return LOGICAL_EXPRESSION_TYPE;
    }

    public evaluate(triggeredValue: any, targetValue: any, formModel: FormModel): boolean {
        const lhsResult = this.lhs.evaluate(triggeredValue, targetValue, formModel);
        if ((!lhsResult && this.operand === LogicalOperand.AND) || (lhsResult && this.operand === LogicalOperand.OR)) {
            return lhsResult;
        }
        const rhsResult = this.rhs.evaluate(triggeredValue, targetValue, formModel);
        return (
            (this.operand === LogicalOperand.AND && lhsResult && rhsResult) ||
            (this.operand === LogicalOperand.OR && (lhsResult || rhsResult))
        );
    }
}

enum ComparisonOperand {
    EQ,
    NE,
    LT,
    GT,
    LE,
    GE,
}

const COMPARISON_EXPRESSION_TYPE = 'comparison';
class ComparisonExpression implements FormInteractionExpression {
    private readonly lhs: FormReference;
    private readonly rhs: FormReference;
    private readonly operand: ComparisonOperand;

    constructor(data: any) {
        this.lhs = new FormReference(data.lhs);
        this.rhs = new FormReference(data.rhs);
        this.operand = ComparisonOperand[data.operand as keyof typeof ComparisonOperand];
    }

    public get type(): string {
        return COMPARISON_EXPRESSION_TYPE;
    }

    public evaluate(triggeredValue: any, targetValue: any, formModel: FormModel): boolean {
        const lhsValue = '' + this.lhs.evaluateValue(triggeredValue, targetValue, formModel);
        const rhsValue = '' + this.rhs.evaluateValue(triggeredValue, targetValue, formModel);
        switch (this.operand) {
            case ComparisonOperand.EQ:
                return lhsValue == rhsValue;
            case ComparisonOperand.NE:
                return lhsValue != rhsValue;
            case ComparisonOperand.LT:
                return lhsValue < rhsValue;
            case ComparisonOperand.GT:
                return lhsValue > rhsValue;
            case ComparisonOperand.LE:
                return lhsValue <= rhsValue;
            case ComparisonOperand.GE:
                return lhsValue >= rhsValue;
            default:
                return false;
        }
    }
}

enum CollectionOperand {
    SIZE_EQ,
    CONTAINS,
}

const COLLECTION_EXPRESSION_TYPE = 'collection';
class CollectionExpression implements FormInteractionExpression {
    private readonly lhs: FormReference;
    private readonly rhs: FormReference;
    private readonly operand: CollectionOperand;

    constructor(data: any) {
        this.lhs = new FormReference(data.lhs);
        this.rhs = new FormReference(data.rhs);
        this.operand = CollectionOperand[data.operand as keyof typeof CollectionOperand];
    }

    public get type(): string {
        return COLLECTION_EXPRESSION_TYPE;
    }

    public evaluate(triggeredValue: any, targetValue: any, formModel: FormModel): boolean {
        const lhsValue = '' + this.lhs.evaluateValue(triggeredValue, targetValue, formModel, this.type);
        const rhsValue = '' + this.rhs.evaluateValue(triggeredValue, targetValue, formModel, this.type);
        switch (this.operand) {
            case CollectionOperand.SIZE_EQ:
                return lhsValue.length === parseInt(rhsValue);
            case CollectionOperand.CONTAINS:
                return lhsValue.includes(rhsValue);
            default:
                return false;
        }
    }
}

function instanceOfLogicalExpression(object: FormInteractionExpression): object is LogicalExpression {
    return object.type === LOGICAL_EXPRESSION_TYPE;
}

function instanceOfComparisonExpression(object: FormInteractionExpression): object is ComparisonExpression {
    return object.type === COMPARISON_EXPRESSION_TYPE;
}

interface FormFieldOperation {
    type: string;
    evaluate(triggeredValue: any, target: FormModelField, formModel: FormModel): void;
}

const SET_VISIBILITY_OPERATION_TYPE = 'set_visibility';
class SetVisibilityOperation implements FormFieldOperation {
    private readonly booleanExpression: FormInteractionExpression;

    constructor(data: any) {
        if (data.booleanExpression.type === LOGICAL_EXPRESSION_TYPE) {
            this.booleanExpression = new LogicalExpression(data.booleanExpression);
        } else if (data.booleanExpression.type === COMPARISON_EXPRESSION_TYPE) {
            this.booleanExpression = new ComparisonExpression(data.booleanExpression);
        } else {
            this.booleanExpression = new CollectionExpression(data.booleanExpression);
        }
    }

    public get type(): string {
        return SET_VISIBILITY_OPERATION_TYPE;
    }

    public evaluate(triggeredValue: any, target: FormModelField, formModel: FormModel): void {
        let value = target.value;
        if (target.options !== null) {
            value = target.options[value];
        }
        target.visible = this.booleanExpression.evaluate(triggeredValue, value, formModel);
    }
}

const FILTER_OPTIONS_OPERATION_TYPE = 'filter_options';
class FilterOptionsOperation implements FormFieldOperation {
    private readonly callbackBooleanExpression: FormInteractionExpression;

    constructor(data: any) {
        if (data.callbackBooleanExpression.type === LOGICAL_EXPRESSION_TYPE) {
            this.callbackBooleanExpression = new LogicalExpression(data.callbackBooleanExpression);
        } else {
            this.callbackBooleanExpression = new ComparisonExpression(data.callbackBooleanExpression);
        }
    }

    public get type(): string {
        return FILTER_OPTIONS_OPERATION_TYPE;
    }

    public evaluate(triggeredValue: any, target: FormModelField, formModel: FormModel): void {
        const options = target.options;
        const disabled: string[] = [];
        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                const option = options[key];
                const success = this.callbackBooleanExpression.evaluate(triggeredValue, option, formModel);
                if (!success) {
                    disabled.push(key);
                }
            }
        }
        target.updateDisabledOptions(disabled);
    }
}

function instanceOfSetVisibilityOperation(object: FormFieldOperation): object is SetVisibilityOperation {
    return object.type === SET_VISIBILITY_OPERATION_TYPE;
}

function instanceOfFilterOptionsOperation(object: FormFieldOperation): object is FilterOptionsOperation {
    return object.type === FILTER_OPTIONS_OPERATION_TYPE;
}

export default class FormFieldInteraction {
    private readonly affectedFormFieldNames: string[];
    private readonly operation: FormFieldOperation;
    private readonly formModel: FormModel;
    private affectedFormFields: FormModelField[] = [];

    constructor(data: any, formModel: FormModel) {
        this.formModel = formModel;
        this.affectedFormFieldNames = data.affectedFormFields;
        if (data.operation.type === SET_VISIBILITY_OPERATION_TYPE) {
            this.operation = new SetVisibilityOperation(data.operation);
        } else {
            this.operation = new FilterOptionsOperation(data.operation);
        }
    }

    public initialize(): void {
        this.affectedFormFields = this.affectedFormFieldNames
            .map((t) => this.findFormField(t)!)
            .filter((t) => t !== null);
    }

    public evaluate(triggeredValue: any): void {
        for (const formField of this.affectedFormFields) {
            this.operation.evaluate(triggeredValue, formField, this.formModel);
        }
    }

    private findFormField(name: string): FormModelField | null {
        for (const group of this.formModel.groups) {
            for (const field of group.fields) {
                if (field.name === name) {
                    return field;
                }
            }
        }
        return null;
    }
}
