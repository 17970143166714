
import { Component, Inject, Vue } from 'vue-property-decorator';
import { OnBeforeDestroy, OnCreated } from '@/util/vue/vueCallbacks';
import AuthRepository from '@/repositories/AuthRepository';
import { Subscription } from 'rxjs';
import LoginScaffold from '@/components/scaffolds/LoginScaffold.vue';
import SimpleFormValidationHandler from '@/util/form/SimpleFormValidationHandler';
import GlobalState from '@/util/GlobalState';

@Component({
    components: { LoginScaffold },
})
export default class SignupScreen extends Vue implements OnCreated, OnBeforeDestroy {
    public firstName = '';
    public surname = '';
    public email = '';
    public password = '';
    public confirmPassword = '';
    public loading = false;
    public completed = false;
    public validationHandler = new SimpleFormValidationHandler([
        'firstName',
        'surname',
        'email',
        'password',
        'confirmPassword',
    ]);
    public backToLoginRoute: any = { name: 'login' };

    @Inject() private readonly globalState!: GlobalState;
    @Inject() private readonly authRepository!: AuthRepository;
    private authSubscription = new Subscription();

    public created(): void {
        this.validationHandler.translator = this.$i18n;
        if (this.$route.query && this.$route.query.hasOwnProperty('login_challenge')) {
            this.backToLoginRoute.query = { login_challenge: this.$route.query.login_challenge };
        }
    }

    public beforeDestroy(): void {
        this.authSubscription.unsubscribe();
    }

    public onSubmit(): void {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.authSubscription = this.authRepository
            .signup(this.firstName, this.surname, this.email, this.password, this.confirmPassword)
            .subscribe((resource) => {
                if (resource.isSuccess) {
                    this.completed = true;
                } else if (resource.isError) {
                    const validationError = this.validationHandler.handleErrorResponse(resource.error);
                    if (validationError) {
                        this.globalState.toast(this.$t('general.validationError').toString());
                    } else {
                        this.globalState.toast(this.$t('general.communicationError').toString());
                    }
                    this.loading = false;
                }
            });
    }

    public get basicRules(): ((input: any) => boolean | string)[] {
        return [(input) => !!input || this.$t('validation.NotBlank').toString()];
    }

    public get passwordRules(): ((input: any) => boolean | string)[] {
        return [(input) => input.length >= 8 || this.$t('validation.ValidPassword').toString()];
    }

    public get passwordConfirmRules(): ((input: any) => boolean | string)[] {
        return [() => this.password === this.confirmPassword || this.$t('validation.FieldsMatch').toString()];
    }
}
