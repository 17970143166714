import { BehaviorSubject, defer, from, Observable } from 'rxjs';
import User from '@/repositories/data/User';
import Resource from '@/repositories/Resource';
import { tap } from 'rxjs/operators';
import AuthManager from '@/util/auth/AuthManager';
import UserRepository from '@/repositories/UserRepository';

export default class GlobalState {
    private readonly _authManager: AuthManager;
    private readonly _userRepository: UserRepository;
    private readonly _toastSubject = new BehaviorSubject<string | null>(null);
    private _currentUser: User | null = null;

    public constructor(authManager: AuthManager, userRepository: UserRepository) {
        this._authManager = authManager;
        this._userRepository = userRepository;
    }

    public generalErrorHandling(error: any): void {
        if (error && error.response) {
            if (error.response.status === 403) {
                // TODO: Review this handling
                // this._authManager.startRedirectLogout();
            }
        }
    }

    public toast(message: string): void {
        this._toastSubject.next(message);
    }

    public get currentToast(): string | null {
        return this._toastSubject.value;
    }

    public get toastObservable(): Observable<string | null> {
        return this._toastSubject.asObservable();
    }

    public get isUserLoaded(): boolean {
        return this._currentUser !== null;
    }

    public get userObservable(): Observable<Resource<User>> {
        // FIXME: If this is subscribed to while a findCurrent is ongoing, multiple calls are made (ReplaySubject?)
        return defer(() => {
            return this._currentUser
                ? from([Resource.success(this._currentUser)])
                : this._userRepository.findCurrent().pipe(
                      tap((userResource) => {
                          if (userResource.isSuccess) {
                              this._currentUser = userResource.data;
                          } else {
                              if (userResource.isError) {
                                  this.generalErrorHandling(userResource.error);
                              }
                              this._currentUser = null;
                          }
                      }),
                  );
        });
    }

    public get userIsNgcAdmin(): boolean {
        if (this._currentUser === null) {
            return false;
        } else {
            return this._currentUser.roles ? this._currentUser.roles.includes('ROLE_ADMIN') : false;
        }
    }

    public readInvitationToken(): string | null {
        return window.localStorage.getItem('invitationToken');
    }

    public saveInvitationToken(token: string): void {
        window.localStorage.setItem('invitationToken', token);
    }

    public clearInvitationToken(): void {
        window.localStorage.removeItem('invitationToken');
    }

    public get currentUser() {
        return this._currentUser;
    }
}
