
import { Component, Inject, Vue } from 'vue-property-decorator';
import { OnBeforeDestroy, OnCreated } from '@/util/vue/vueCallbacks';
import AuthRepository from '@/repositories/AuthRepository';
import { Subscription } from 'rxjs';
import LoginScaffold from '@/components/scaffolds/LoginScaffold.vue';
import GlobalState from '@/util/GlobalState';
import SimpleFormValidationHandler from '@/util/form/SimpleFormValidationHandler';
import AuthManager from '@/util/auth/AuthManager';

@Component({
    components: { LoginScaffold },
})
export default class LoginScreen extends Vue implements OnCreated, OnBeforeDestroy {
    public email = '';
    public password = '';
    public challenge = '';
    public error = false;
    public loading = false;
    public afterLogout = false;
    public validationHandler = new SimpleFormValidationHandler(['email', 'password']);

    @Inject() private readonly globalState!: GlobalState;
    @Inject() private readonly authManager!: AuthManager;
    @Inject() private readonly authRepository!: AuthRepository;
    private authSubscription = new Subscription();

    public get basicRules(): ((input: any) => boolean | string)[] {
        return [(input) => !!input || this.$t('validation.NotBlank').toString()];
    }

    public get loginProviders() {
        return this.authRepository.getLoginProviders(this.challenge);
    }

    public created(): void {
        this.validationHandler.translator = this.$i18n;
        if (this.$route.query && this.$route.query.hasOwnProperty('login_challenge')) {
            this.challenge = '' + this.$route.query.login_challenge;
        } else if (this.$route.query && this.$route.query.hasOwnProperty('success')) {
            this.authSubscription = this.authManager
                .validateLogin(
                    this.$route.query.hasOwnProperty('code') ? (this.$route.query.code as string) : null,
                    this.$route.query.hasOwnProperty('state') ? (this.$route.query.state as string) : null,
                )
                .subscribe((tokenResource) => {
                    if (tokenResource.isSuccess) {
                        this.$router.push({ name: 'home' });
                    } else if (tokenResource.isError) {
                        this.error = true;
                    }
                });
        } else if (this.$route.name === 'logout') {
            if (this.authManager.token !== null) {
                this.authManager.startRedirectLogout();
            } else {
                this.afterLogout = true;
            }
        } else {
            this.authManager.startRedirectLogin();
        }
    }

    public beforeDestroy(): void {
        this.authSubscription.unsubscribe();
    }

    public onRetry() {
        this.authManager.startRedirectLogin();
    }

    public onSubmit(): void {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.authSubscription.unsubscribe();
        this.validationHandler.clearValidation();
        this.authSubscription = this.authRepository
            .login(this.email, this.password, this.challenge)
            .subscribe((loginResponseResource) => {
                if (loginResponseResource.isSuccess && loginResponseResource.data) {
                    if (loginResponseResource.data.requiresSecondFactor) {
                        this.$router.push({ name: 'login2fa', query: { login_challenge: this.challenge } });
                    } else {
                        window.location.href = this.authRepository.nextUri;
                    }
                } else if (loginResponseResource.isError) {
                    const validationError = this.validationHandler.handleErrorResponse(loginResponseResource.error);
                    if (validationError) {
                        this.globalState.toast(this.$t('login.validationError').toString());
                    } else {
                        this.globalState.toast(this.$t('general.communicationError').toString());
                    }
                    this.loading = false;
                }
            });
    }
}
