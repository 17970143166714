
import { Component, Inject, Vue } from 'vue-property-decorator';
import DashboardDataTable from '@/components/DashboardDataTable.vue';
import GeneralScaffold from '@/components/scaffolds/GeneralScaffold.vue';
import GlobalState from '@/util/GlobalState';
import PagedDataTable from '@/components/PagedDataTable.vue';
import DashboardOrganizationRepository from '@/repositories/DashboardOrganizationRepository';
import ExportDialog from '@/components/ExportDialog.vue';

@Component({
    components: {
        ExportDialog,
        PagedDataTable,
        GeneralScaffold,
        DashboardDataTable,
    },
})
export default class HomeScreen extends Vue {
    @Inject()
    private readonly globalState!: GlobalState;

    @Inject()
    private readonly dashboardOrganizationRepository!: DashboardOrganizationRepository;

    public showExportDialog = false;

    public created(): void {
        const invitationToken = this.globalState.readInvitationToken();
        if (invitationToken !== null) {
            this.$router.push({ name: 'invitationAcceptForm', query: { token: invitationToken } });
        }
    }

    public get subscriptionHeaders(): any[] {
        return [
            {
                text: this.$t('general.actions'),
                value: 'action',
                sortable: false,
            },
            {
                text: this.$t('crud.organization.name'),
                align: 'left',
                value: 'name',
                sortable: false,
            },
        ];
    }
}
