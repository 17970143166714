
import { Component, Provide, Vue } from 'vue-property-decorator';
import { Subscription } from 'rxjs';
import OrganizationRepository from '@/repositories/OrganizationRepository';
import FileRepository from '@/repositories/FileRepository';
import UserRepository from '@/repositories/UserRepository';
import AuthRepository from '@/repositories/AuthRepository';
import AuthManager from '@/util/auth/AuthManager';
import GlobalState from '@/util/GlobalState';
import {
    organizationRepository,
    fileRepository,
    globalState,
    authRepository,
    authManager,
    userRepository,
    subscriptionRepository,
    organizationInviteRepository,
    organizationGroupRepository,
    organizationMemberRepository,
    subscriptionConsumerRepository,
    publicOrganizationInvitationRepository,
    subscriptionMassRepository,
    dashboardOrganizationRepository,
} from '@/container';
import SubscriptionRepository from '@/repositories/SubscriptionRepository';
import OrganizationInviteRepository from '@/repositories/OrganizationInviteRepository';
import OrganizationMemberRepository from '@/repositories/OrganizationMemberRepository';
import SubscriptionConsumerRepository from '@/repositories/SubscriptionConsumerRepository';
import PublicOrganizationInvitationRepository from '@/repositories/PublicOrganizationInvitationRepository';
import OrganizationGroupRepository from '@/repositories/OrganizationGroupRepository';
import SubscriptionMassRepository from '@/repositories/SubscriptionMassRepository';
import DashboardOrganizationRepository from '@/repositories/DashboardOrganizationRepository';

@Component
export default class App extends Vue {
    @Provide()
    public organizationRepository: OrganizationRepository = organizationRepository;

    @Provide()
    public organizationInviteRepository: OrganizationInviteRepository = organizationInviteRepository;

    @Provide()
    public organizationGroupRepository: OrganizationGroupRepository = organizationGroupRepository;

    @Provide()
    public organizationMemberRepository: OrganizationMemberRepository = organizationMemberRepository;

    @Provide()
    public fileRepository: FileRepository = fileRepository;

    @Provide()
    public userRepository: UserRepository = userRepository;

    @Provide()
    public authRepository: AuthRepository = authRepository;

    @Provide()
    public dashboardOrganizationRepository: DashboardOrganizationRepository = dashboardOrganizationRepository;

    @Provide()
    public subscriptionRepository: SubscriptionRepository = subscriptionRepository;

    @Provide()
    public subscriptionMassRepository: SubscriptionMassRepository = subscriptionMassRepository;

    @Provide()
    public subscriptionConsumerRepository: SubscriptionConsumerRepository = subscriptionConsumerRepository;

    @Provide()
    public publicOrganizationInvitationRepository: PublicOrganizationInvitationRepository =
        publicOrganizationInvitationRepository;

    @Provide()
    public authManager: AuthManager = authManager;

    @Provide()
    public globalState: GlobalState = globalState;

    private showToast = false;
    private toastSubscription = new Subscription();

    public created(): void {
        this.toastSubscription = this.globalState.toastObservable.subscribe((toast) => {
            this.showToast = toast !== null;
        });
    }

    public beforeDestroy(): void {
        this.toastSubscription.unsubscribe();
    }
}
