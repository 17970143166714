
import { Component, Inject, Vue } from 'vue-property-decorator';
import AuthManager from '@/util/auth/AuthManager';
import { OnBeforeDestroy, OnCreated } from '@/util/vue/vueCallbacks';
import GlobalState from '@/util/GlobalState';
import User from '@/repositories/data/User';
import { Subscription } from 'rxjs';

interface NavItem {
    label: string;
    target: string;
    targetParams: any;
    group: string;
    roles: string[];
}

@Component
export default class GeneralScaffold extends Vue implements OnCreated, OnBeforeDestroy {
    public navItems: NavItem[] = [];

    @Inject() private readonly authManager!: AuthManager;
    @Inject() private readonly globalState!: GlobalState;
    private drawer: boolean = true;
    private _user: User | null = null;
    private _userSubscription = new Subscription();

    public created(): void {
        this._userSubscription = this.globalState.userObservable.subscribe((userResource) => {
            if (userResource.isSuccess) {
                this._user = userResource.data;
                const roles = this._user == null || !this._user.roles ? [] : this._user.roles;
                if (roles.length > 0) {
                    this.navItems = [
                        {
                            label: this.$t('navigation.home').toString(),
                            target: 'home',
                            targetParams: {},
                            group: 'home',
                            roles: ['ROLE_USER'],
                        },
                        {
                            label: this.$t('navigation.account').toString(),
                            target: 'userForm',
                            targetParams: { id: this.globalState.currentUser!.id },
                            group: 'account',
                            roles: ['ROLE_USER'],
                        },
                        {
                            label: this.$t('navigation.users').toString(),
                            target: 'userList',
                            targetParams: {},
                            group: 'user',
                            roles: ['ROLE_ADMIN'],
                        },
                        {
                            label: this.$t('navigation.organizations').toString(),
                            target: 'organizationList',
                            targetParams: {},
                            group: 'organization',
                            roles: ['ROLE_ADMIN', 'ROLE_USER'],
                        },
                        {
                            label: this.$t('navigation.subscriptions').toString(),
                            target: 'subscriptionList',
                            targetParams: {},
                            group: 'subscription',
                            roles: ['ROLE_ADMIN', 'ROLE_USER'],
                        },
                    ].filter((item) => item.roles.some((role) => roles.includes(role)));
                }
            } else if (userResource.isError) {
                this.globalState.toast(this.$t('general.noUserError').toString());
            }
        });
    }

    public beforeDestroy(): void {
        this._userSubscription.unsubscribe();
    }

    public onLogout(): void {
        this.authManager.startRedirectLogout();
    }

    public get activeNavGroup(): string | null {
        if (this.$route.meta && this.$route.meta.navGroup) {
            return this.$route.meta.navGroup;
        }
        return null;
    }

    public set activeNavGroup(group: string | null) {
        if (group) {
            const item = this.navItems.find((t) => t.group === group);
            if (item) {
                this.onNavigate(item.target, item.targetParams);
            }
        }
    }

    private onNavigate(target: string, params: any): void {
        if (this.$route.name !== target) {
            this.$router.push({ name: target, params });
        }
    }
}
