
import { Component, Inject, Vue } from 'vue-property-decorator';
import SimpleFormValidationHandler from '@/util/form/SimpleFormValidationHandler';
import GlobalState from '@/util/GlobalState';
import AuthManager from '@/util/auth/AuthManager';
import AuthRepository from '@/repositories/AuthRepository';
import { Subscription } from 'rxjs';
import LoginScaffold from '@/components/scaffolds/LoginScaffold.vue';
import { OnBeforeDestroy, OnCreated } from '@/util/vue/vueCallbacks';

@Component({
    components: { LoginScaffold },
})
export default class Login2FaScreen extends Vue implements OnCreated, OnBeforeDestroy {
    public token = '';
    public challenge = '';
    public error = false;
    public loading = false;
    public validationHandler = new SimpleFormValidationHandler(['token']);

    @Inject() private readonly globalState!: GlobalState;
    @Inject() private readonly authManager!: AuthManager;
    @Inject() private readonly authRepository!: AuthRepository;
    private authSubscription = new Subscription();

    public get basicRules(): ((input: any) => boolean | string)[] {
        return [(input) => !!input || this.$t('validation.NotBlank').toString()];
    }

    public created(): void {
        this.validationHandler.translator = this.$i18n;
        if (this.$route.query.hasOwnProperty('login_challenge')) {
            this.challenge = '' + this.$route.query.login_challenge;
        } else {
            this.authManager.startRedirectLogin();
        }
    }

    public beforeDestroy(): void {
        this.authSubscription.unsubscribe();
    }

    public onRetry() {
        this.authManager.startRedirectLogin();
    }

    public onSubmit(): void {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.authSubscription.unsubscribe();
        this.validationHandler.clearValidation();
        this.authSubscription = this.authRepository.secondFactor(this.token).subscribe((loginResponseResource) => {
            if (loginResponseResource.isSuccess && loginResponseResource.data) {
                if (loginResponseResource.data.requiresSecondFactor) {
                    this.token = '';
                    this.loading = false;
                } else {
                    window.location.href = this.authRepository.nextUri;
                }
            } else if (loginResponseResource.isError) {
                const validationError = this.validationHandler.handleErrorResponse(loginResponseResource.error);
                if (validationError) {
                    this.globalState.toast(this.$t('login.validationError').toString());
                } else {
                    this.globalState.toast(this.$t('general.communicationError').toString());
                }
                this.loading = false;
            }
        });
    }
}
