
import { Component, Inject, Vue } from 'vue-property-decorator';
import OrganizationRepository from '@/repositories/OrganizationRepository';
import GeneralScaffold from '@/components/scaffolds/GeneralScaffold.vue';
import PagedDataTable from '@/components/PagedDataTable.vue';

@Component({
    components: { GeneralScaffold, PagedDataTable },
})
export default class OrganizationListScreen extends Vue {
    @Inject()
    private readonly organizationRepository!: OrganizationRepository;

    public get headers(): any[] {
        return [
            {
                text: this.$t('general.actions'),
                value: 'action',
                sortable: false,
            },
            {
                text: this.$t('crud.organization.name'),
                align: 'left',
                value: 'name',
                sortable: false,
            },
        ];
    }
}
