import BaseCrudRepository, { Pageable } from '@/repositories/BaseCrudRepository';
import HttpClient from '@/util/http/HttpClient';
import Subscription from '@/repositories/data/Subscription';
import { Observable } from 'rxjs';
import Resource from '@/repositories/Resource';
import Page from '@/repositories/data/Page';
import { FormModelFieldOption } from '@/repositories/data/FormModel';

export default class SubscriptionMassRepository extends BaseCrudRepository<Subscription, Subscription, number> {
    public constructor(client: HttpClient) {
        super(client, 'api/subscriptions-mass', 'SUBSCRIPTIONS');
    }

    public introspectOptions(
        type: string,
        parentId: any,
        id: number | null,
        value: any,
        pageable: Pageable,
    ): Observable<Resource<Page<FormModelFieldOption>>> {
        return super.introspectOptions(type, parentId, id, Array.isArray(value) ? value.join(',') : value, pageable);
    }

    protected formatIntrospectUrl(create: boolean, id: number, parentId?: any): string {
        return 'introspect';
    }
}
