
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseCrudRepository from '@/repositories/BaseCrudRepository';
import FormModel from '@/repositories/data/FormModel';
import FileUploader from '@/components/form/FileUploader.vue';
import EmbeddedForm from '@/util/form/EmbeddedForm';
import FormModelEmbed from '@/components/form/FormModelEmbed.vue';
import ServerBasedEnumFormField from '@/components/form/ServerBasedEnumFormField.vue';

@Component({
    components: {
        FileUploader,
        ServerBasedEnumFormField,
        FormModelEmbed,
    },
})
export default class FormModelForm extends Vue {
    @Prop({ type: Boolean, required: false, default: () => true })
    public loading!: boolean;

    @Prop({ type: Object, required: true })
    private readonly entityRepository!: BaseCrudRepository<any, any, any>;

    @Prop({ type: String, required: true })
    private readonly i18nGroup!: string;

    @Prop({ type: Object, required: false, default: () => new FormModel([]) })
    private readonly formModel!: FormModel;

    @Prop({ type: Array, required: false, default: () => [] })
    private readonly embeds!: EmbeddedForm[];

    @Prop({ type: Array, required: false, default: () => [] })
    private readonly hiddenFields!: string[];

    @Prop({ type: Number, required: false, default: 0 })
    private readonly parentId!: number;

    public modal: { key: string; value: boolean } = {} as { key: string; value: boolean };

    private groupHasVisibleFields(groupName: string): boolean {
        for (let group of this.formModel.groups) {
            if (group.name === groupName) {
                for (let field of group.fields) {
                    if (!this.hiddenFields.includes(field.name) && field.visible) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private findEmbeddedFormData(embedType: string): EmbeddedForm | null {
        const relationship = embedType.substring('embed:'.length);
        const result = this.embeds.find((t) => t.relationship === relationship);
        return result ? result : null;
    }

    private onError(error: any) {
        this.$emit('error', error);
    }
}
