
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { OnBeforeDestroy, OnCreated } from '@/util/vue/vueCallbacks';
import LoginScaffold from '@/components/scaffolds/LoginScaffold.vue';
import SimpleFormValidationHandler from '@/util/form/SimpleFormValidationHandler';
import AuthRepository from '@/repositories/AuthRepository';
import { Subscription } from 'rxjs';
import GlobalState from '@/util/GlobalState';
import { RawLocation } from 'vue-router';

@Component({
    components: { LoginScaffold },
})
export default class ForgotPasswordScreen extends Vue implements OnCreated, OnBeforeDestroy {
    public email = '';
    public password = '';
    public confirmPassword = '';
    public loading = false;
    public completed = false;
    public validationHandler = new SimpleFormValidationHandler(['email', 'password', 'confirmPassword']);

    @Inject() private readonly globalState!: GlobalState;
    @Inject() private readonly authRepository!: AuthRepository;
    private code = '';
    private authSubscription = new Subscription();

    public created(): void {
        this.updateCode();
    }

    public beforeDestroy(): void {
        this.authSubscription.unsubscribe();
    }

    @Watch('$route')
    public onRouteChanged(): void {
        this.updateCode();
    }

    public onSubmit(): void {
        this.authSubscription.unsubscribe();
        this.authSubscription = (
            this.hasCode
                ? this.authRepository.resetPassword(this.code, this.password, this.confirmPassword)
                : this.authRepository.requestPasswordResetToken(this.email)
        ).subscribe((resource) => {
            this.loading = resource.isLoading;
            if (resource.isSuccess) {
                this.completed = true;
            } else if (resource.isError) {
                const validationError = this.validationHandler.handleErrorResponse(resource.error);
                if (validationError) {
                    this.globalState.toast(this.$t('general.validationError').toString());
                } else {
                    this.globalState.toast(this.$t('general.communicationError').toString());
                }
            }
        });
    }

    public get hasCode(): boolean {
        return this.code.length > 0;
    }

    public get basicRules(): ((input: any) => boolean | string)[] {
        return [(input) => !!input || this.$t('validation.NotBlank').toString()];
    }

    public get passwordRules(): ((input: any) => boolean | string)[] {
        return [(input) => input.length >= 8 || this.$t('validation.ValidPassword').toString()];
    }

    public get passwordConfirmRules(): ((input: any) => boolean | string)[] {
        return [() => this.password === this.confirmPassword || this.$t('validation.FieldsMatch').toString()];
    }

    public get backToLoginRoute(): RawLocation {
        const location: RawLocation = { name: 'login' };
        if (this.$route.query && this.$route.query.hasOwnProperty('login_challenge')) {
            location.query = { login_challenge: this.$route.query.challenge };
        }
        return location;
    }

    private updateCode(): void {
        if (this.$route.params && this.$route.params.hasOwnProperty('code')) {
            this.code = this.$route.params.code as string;
        }
    }
}
