
import { Component, Inject, Vue } from 'vue-property-decorator';
import GeneralScaffold from '@/components/scaffolds/GeneralScaffold.vue';
import FormModelDisplay from '@/components/form/FormModelDisplay.vue';
import SubscriptionRepository from '@/repositories/SubscriptionRepository';
import SubscriptionConsumerRepository from '@/repositories/SubscriptionConsumerRepository';
import EmbeddedForm from '@/util/form/EmbeddedForm';

@Component({
    components: {
        GeneralScaffold,
        FormModelDisplay,
    },
})
export default class SubscriptionFormScreen extends Vue {
    @Inject()
    private readonly subscriptionRepository!: SubscriptionRepository;
    @Inject()
    private readonly subscriptionConsumerRepository!: SubscriptionConsumerRepository;

    public get embeds(): EmbeddedForm[] {
        return [
            {
                relationship: 'consumers',
                parentRefKey: 'subscriptionId',
                i18nGroup: 'subscriptionConsumer',
                entityRepository: this.subscriptionConsumerRepository,
                columns: [
                    {
                        text: this.$t('general.actions'),
                        value: 'action',
                        sortable: false,
                    },
                    {
                        text: this.$t('crud.subscriptionConsumer.name'),
                        value: 'name',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.subscriptionConsumer.label'),
                        value: 'label',
                        sortable: true,
                    },
                    {
                        text: this.$t('crud.subscriptionConsumer.manager'),
                        value: 'manager',
                        sortable: true,
                    },
                ],
            },
        ];
    }
}
