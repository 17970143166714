
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ToolbarInnerScaffold extends Vue {
    @Prop({ type: String, required: true })
    private readonly title!: string;

    @Prop({ type: Boolean, required: false, default: false })
    private readonly showAddButton!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    private readonly disableAddButton!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    private readonly showSaveButton!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    private readonly disableSaveButton!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    private readonly showDeleteButton!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    private readonly disableDeleteButton!: boolean;

    private onAdd(): void {
        this.$emit('add');
    }

    private onSave(): void {
        this.$emit('save');
    }

    private onDelete(): void {
        this.$emit('delete');
    }
}
