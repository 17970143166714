import HttpClient from '@/util/http/HttpClient';
import FileUpload from '@/repositories/data/FileUpload';
import { Observable } from 'rxjs';
import ApiWrapper from '@/repositories/data/ApiWrapper';
import Resource from '@/repositories/Resource';
import unwrapApiWrapper from '@/repositories/unwrapApiWrapper';
import networkBoundResource from '@/repositories/networkBoundResource';

export default class FileRepository {
    protected readonly client: HttpClient;
    protected readonly baseUrl: string;

    public constructor(client: HttpClient) {
        this.client = client;
        this.baseUrl = process.env.VUE_APP_API_ENDPOINT + '/file/';
    }

    public getFile(id: number): Observable<Resource<FileUpload>> {
        const observable = this.client.get<ApiWrapper<FileUpload>>('' + id, { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public getFileUrl(id: number): string {
        return process.env.VUE_APP_API_ENDPOINT + '/file/' + id + '/download';
    }

    public uploadFile(file: File): Observable<Resource<FileUpload>> {
        const formData = new FormData();
        formData.append('file', file);
        const observable = this.client.post<ApiWrapper<FileUpload>>('upload', formData, { baseUrl: this.baseUrl });
        return networkBoundResource(unwrapApiWrapper(observable));
    }
}
