
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseCrudRepository, { Pageable, SortDirection } from '@/repositories/BaseCrudRepository';
import { BehaviorSubject, combineLatest, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import EmbeddedForm from '@/util/form/EmbeddedForm';
import FormModel from '@/repositories/data/FormModel';
import GlobalState from '@/util/GlobalState';
import PagedDataTable from '@/components/PagedDataTable.vue';
import Page from '@/repositories/data/Page';
import SubscriptionRepository from '@/repositories/SubscriptionRepository';
import ValidationErrorResponse from '@/repositories/data/ValidationErrorResponse';

interface Options {
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
}

const defaultOptions: Options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
};

@Component({
    components: { PagedDataTable },
})
export default class FormModelEmbed extends Vue {
    @Inject()
    private readonly globalState!: GlobalState;

    @Prop({ type: Object, required: true })
    private readonly embed!: EmbeddedForm;

    @Prop({ type: Object, required: true })
    private readonly parentEntityRepository!: BaseCrudRepository<any, any, any>;

    @Prop({ required: true })
    private readonly parentId!: any;

    @Prop({ type: Boolean, required: false, default: true })
    private readonly showAddButton!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    private readonly showEditButton!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    private readonly showDeleteButton!: boolean;

    private loading = true;
    private formModel: FormModel | null = null;
    private formDialog = false;
    private formLoading = false;
    private deleteDialog = false;
    private deleteTarget: any = null;
    private deleting = false;
    private deleteSubscription = new Subscription();
    private parentRepositorySubject = new ReplaySubject<BaseCrudRepository<any, any, any>>(1);
    private parentIdSubject = new ReplaySubject<number>(1);
    private relationshipSubject = new ReplaySubject<string>(1);
    private listingSubscription = new Subscription();
    private formModelSubscription = new Subscription();
    private tableOptions: Options = { ...defaultOptions };
    private tableOptionsSubject = new BehaviorSubject<Options>({ ...defaultOptions });
    private pageSubscription = new Subscription();
    private pageSubject = new BehaviorSubject<Page<any>>({
        content: [],
        total: 0,
        pageable: {
            page: 0,
            size: 1,
        },
    });
    private searchSubject = new BehaviorSubject('');
    private search: string = '';

    public created(): void {
        this.listingSubscription = combineLatest([
            this.parentRepositorySubject,
            this.parentIdSubject,
            this.relationshipSubject,
            this.tableOptionsSubject,
            this.searchSubject.pipe(debounceTime(250)),
        ])
            .pipe(
                switchMap(([repository, id, relationship, tableOptions, search]) =>
                    repository.findRelatedPage(id, relationship, this.tableOptionsToPageable(tableOptions), search),
                ),
            )
            .subscribe((relatedResource) => {
                if (relatedResource.isSuccess && relatedResource.data) {
                    this.pageSubject.next(relatedResource.data);
                }
                this.loading = relatedResource.isLoading;
                if (relatedResource.isError) {
                    let validationError = false;
                    const error = relatedResource.error;
                    if (
                        error.response &&
                        error.response.status === 400 &&
                        error.response.data &&
                        error.response.data.data &&
                        error.response.data.data.validationError
                    ) {
                        const validationErrorResponse: ValidationErrorResponse = error.response.data.data;
                        validationError = true;
                        if (this.formModel) {
                            this.formModel.processValidationErrorResponse(validationErrorResponse);
                        }
                    }
                    if (validationError) {
                        this.globalState.toast(this.$t('general.validationError').toString());
                    } else {
                        this.$emit('error', relatedResource.error);
                        this.globalState.generalErrorHandling(relatedResource.error);
                    }
                }
            });
    }

    public beforeDestroy(): void {
        this.deleteSubscription.unsubscribe();
        this.listingSubscription.unsubscribe();
        this.formModelSubscription.unsubscribe();
        this.pageSubscription.unsubscribe();
    }

    private tableOptionsToPageable(options: Options): Pageable {
        return {
            page: options.page - 1,
            size: options.itemsPerPage,
            sort: options.sortBy.map((t, i) => ({
                property: t,
                direction: options.sortDesc[i] ? SortDirection.DESC : SortDirection.ASC,
            })),
        };
    }

    private getIntrospect(create: boolean, id: any) {
        return this.embed.entityRepository.introspect(create, id, this.parentId);
    }

    private onNew(): void {
        this.formModelSubscription.unsubscribe();
        this.formModel = null;
        this.formDialog = true;
        this.formModelSubscription = this.getIntrospect(true, 0).subscribe((formModelResource) => {
            if (formModelResource.isSuccess) {
                this.formModel = formModelResource.data;
            }
            if (formModelResource.isError) {
                this.$emit('error', formModelResource.error);
            }
            this.formLoading = formModelResource.isLoading;
        });
    }

    private editItem(item: any): void {
        this.formModelSubscription.unsubscribe();
        this.formModel = null;
        this.formDialog = true;
        this.formModelSubscription = combineLatest([
            this.getIntrospect(false, item.id),
            this.embed.entityRepository.findOne(item.id),
        ])
            .pipe(
                map(([formModelResource, entityResource]) =>
                    formModelResource.merge(entityResource, (formModel, entity) =>
                        formModel !== null ? formModel.withHydratedValues(entity) : null,
                    ),
                ),
            )
            .subscribe((formModelResource) => {
                if (formModelResource.isSuccess) {
                    this.formModel = formModelResource.data;
                }
                if (formModelResource.isError) {
                    this.$emit('error', formModelResource.error);
                }
                this.formLoading = formModelResource.isLoading;
            });
    }

    private cancelForm(): void {
        this.formModelSubscription.unsubscribe();
        this.formModel = null;
        this.formDialog = false;
    }

    private onSubmit(): void {
        if (this.formModel !== null) {
            const model = this.formModel.extractData<any>();
            const isNew = this.formModel.isNew;
            model[this.embed.parentRefKey] = this.parentId;
            this.formModel.clearValidationState();
            this.formModelSubscription.unsubscribe();
            this.formModelSubscription = (
                isNew ? this.embed.entityRepository.create(model) : this.embed.entityRepository.update(model)
            ).subscribe((resource) => {
                if (resource.isSuccess) {
                    this.formModel = null;
                    this.formDialog = false;
                    this.relationshipSubject.next(this.embed.relationship);
                    this.globalState.toast(
                        this.$t('crud.' + this.i18nGroup + (isNew ? '.ui.created' : '.ui.updated')).toString(),
                    );
                } else if (resource.isError) {
                    let validationError = false;
                    const error = resource.error;
                    if (
                        error.response &&
                        error.response.status === 400 &&
                        error.response.data &&
                        error.response.data.data &&
                        error.response.data.data.validationError
                    ) {
                        const validationErrorResponse: ValidationErrorResponse = error.response.data.data;
                        validationError = true;
                        if (this.formModel) {
                            this.formModel.processValidationErrorResponse(validationErrorResponse);
                        }
                    }
                    if (validationError) {
                        this.globalState.toast(this.$t('general.validationError').toString());
                    } else {
                        this.$emit('error', resource.error);
                    }
                }
            });
        }
    }

    private deleteItem(item: any): void {
        this.deleteTarget = item;
        this.deleteDialog = true;
    }

    private confirmDelete(): void {
        if (this.deleteTarget) {
            this.deleteSubscription.unsubscribe();
            this.deleting = true;
            this.deleteSubscription = this.embed.entityRepository.delete(this.deleteTarget.id).subscribe((resource) => {
                this.deleting = resource.isLoading;
                this.deleteDialog = false;
                if (!resource.isLoading) {
                    this.relationshipSubject.next(this.embed.relationship);
                }
                if (resource.isError) {
                    this.$emit('error', resource.error);
                }
            });
        }
    }

    private cancelDelete(): void {
        this.deleteSubscription.unsubscribe();
        this.deleting = false;
        this.deleteDialog = false;
        this.deleteTarget = null;
    }

    private onError(error: any) {
        this.$emit('error', error);
    }

    @Watch('parentEntityRepository', { immediate: true })
    private onParentEntityRepositoryChanged(repository: BaseCrudRepository<any, any, any>): void {
        this.parentRepositorySubject.next(repository);
    }

    @Watch('parentId', { immediate: true })
    private onParentIdChanged(id: any): void {
        this.parentIdSubject.next(id);
    }

    @Watch('embed', { immediate: true })
    private onEmbedChanged(embed: EmbeddedForm): void {
        this.relationshipSubject.next(embed.relationship);
    }

    private get i18nGroup(): string {
        return this.embed.i18nGroup;
    }

    private get columns(): string[] {
        return this.embed.columns;
    }

    public get items(): any[] {
        return this.pageSubject.value.content.map((t) => ({
            ...t,
            safeId: typeof t.id === 'object' ? JSON.stringify(t.id) : '' + t.id,
        }));
    }

    public get itemsTotal(): number {
        return this.pageSubject.value.total;
    }

    @Watch('tableOptions', { deep: true })
    private onTableOptionsChanged(options: any): void {
        this.tableOptionsSubject.next(options);
    }

    @Watch('search')
    private onSearchChanged(search: string): void {
        this.searchSubject.next(search);
    }

    public get hiddenFields(): string[] {
        if (
            this.embed.parentRefKey === 'organizationId' &&
            this.embed.entityRepository instanceof SubscriptionRepository
        ) {
            return [this.embed.parentRefKey, 'isOrganizationConsumer', 'userId'];
        } else if (
            this.embed.parentRefKey === 'userId' &&
            this.embed.entityRepository instanceof SubscriptionRepository
        ) {
            return [this.embed.parentRefKey, 'isOrganizationConsumer', 'organizationId', 'consumers'];
        } else if (
            this.embed.parentRefKey === 'id.userId' &&
            this.embed.entityRepository instanceof SubscriptionRepository
        ) {
            return [this.embed.parentRefKey, 'isOrganizationConsumer', 'userId', 'organizationId', 'consumers'];
        } else {
            return [this.embed.parentRefKey];
        }
    }
}
