import VueI18n, { IVueI18n } from 'vue-i18n';
import ValidationErrorResponse, { Violation } from '@/repositories/data/ValidationErrorResponse';
import { BehaviorSubject, Observable } from 'rxjs';
import FormFieldInteraction from '@/repositories/data/FormFieldInteraction';
import { map } from 'rxjs/operators';
import EmbedActions from '@/repositories/data/EmbedActions';

interface FormModelLifecycleAware {
    onFormModelCreated(): void;
}

export interface FormModelFieldOption {
    key: string;
    label: string;
    properties: { [key: string]: any };
}

interface FormModelFieldOptions {
    [key: string]: FormModelFieldOption;
}

interface ValidationRule {
    rule: string;
    value?: any;
}

export class FormModelField implements FormModelLifecycleAware {
    private _validState: boolean | null = null;
    private _ruleViolations: Violation[] = [];
    private _valueSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _visible: boolean = true;
    private _disabledOptions: string[] = [];

    constructor(
        private _name: string,
        private _type: string,
        private _options: FormModelFieldOptions,
        private _multiple: boolean,
        private _filterable: boolean,
        private _validationRules: ValidationRule[],
        private _interactions: FormFieldInteraction[],
        private _editableOnUpdate: boolean,
        private _disabled: boolean,
        private _embedActions: EmbedActions | null,
    ) {
        this.resetValue();
    }

    public onFormModelCreated(): void {
        if (this._interactions.length > 0) {
            for (const interaction of this._interactions) {
                interaction.initialize();
            }
            this._valueSubject.pipe(map((t) => (this._options !== null ? this._options[t] : t))).subscribe((value) => {
                for (const interaction of this._interactions) {
                    interaction.evaluate(value);
                }
            });
        }
    }

    public resetValue(): void {
        if (this._multiple) {
            this.value = [];
        } else if (this.isRequired) {
            if (this._type === 'boolean') {
                this.value = false;
            } else if (this._type === 'text') {
                this.value = '';
            } else if (this._type === 'UnitDependableValue') {
                this.value = { value: 0, unit: Object.keys(this._options)[0] };
            } else if (this._type === 'integer' || this._type === 'decimal') {
                this.value = 0;
            } else if (this._type === 'enum') {
                this.value = Object.keys(this._options)[0];
            } else {
                this.value = null;
            }
        } else {
            if (this._type === 'UnitDependableValue') {
                this.value = { value: null, unit: null };
            } else {
                this.value = null;
            }
        }
    }

    public reportRuleViolation(violation: Violation): void {
        this._ruleViolations.push(violation);
        this._validState = false;
    }

    public clearValidationState(): void {
        this._validState = null;
        this._ruleViolations = [];
    }

    public translateInvalidFeedback($i18n: VueI18n & IVueI18n): string[] {
        const feedback: string[] = [];
        for (const violation of this._ruleViolations) {
            const bracketPos = violation.field.indexOf('[');
            if (bracketPos !== -1) {
                const bracketEnd = violation.field.indexOf(']');
                const index = parseInt(violation.field.substring(bracketPos + 1, bracketEnd), 10);
                const rawValue = this._valueSubject.value;
                const value =
                    typeof rawValue === 'string' ? this._valueSubject.value.split('\n')[index] : rawValue[index];
                feedback.push(
                    $i18n.t('validation.' + violation.rule + 'Array', { index: index + 1, value }).toString(),
                );
            } else if (violation.hasOwnProperty('value')) {
                feedback.push($i18n.t('validation.' + violation.rule, { value: violation.value }).toString());
            } else {
                feedback.push($i18n.t('validation.' + violation.rule).toString());
            }
        }
        return feedback;
    }

    public dynamicRules($i18n: VueI18n & IVueI18n): ((value: any) => string | boolean)[] {
        return this._validationRules.map((rule) => {
            if (rule.rule === 'NotNull' || rule.rule === 'NotBlank') {
                return (value) =>
                    value !== null && value !== undefined && value.length > 0
                        ? true
                        : $i18n.t('validation.' + rule.rule).toString();
            } else if (rule.rule === 'Min') {
                return (value) =>
                    value >= rule.value ? true : $i18n.t('validation.' + rule.rule, { value: rule.value }).toString();
            } else if (rule.rule === 'Max') {
                return (value) =>
                    value <= rule.value ? true : $i18n.t('validation.' + rule.rule, { value: rule.value }).toString();
            }
            return () => true;
        });
    }

    public updateDisabledOptions(value: string[]) {
        if (value.includes(this.value)) {
            this.value = null;
        }
        this._disabledOptions = value;
    }

    public get editableOnUpdate(): boolean {
        return this._editableOnUpdate;
    }

    public get isDisabled(): boolean {
        return this._disabled;
    }

    public get isEmbedCreateAllowed(): boolean {
        return !this._disabled && this._embedActions !== null && this._embedActions.create;
    }

    public get isEmbedUpdateAllowed(): boolean {
        return !this._disabled && this._embedActions !== null && this._embedActions.update;
    }

    public get isEmbedDeleteAllowed(): boolean {
        return !this._disabled && this._embedActions !== null && this._embedActions.delete;
    }

    public get value(): any {
        return this._valueSubject.getValue();
    }

    public set value(value: any) {
        let parsedValue = value;
        if (this._type === 'enum' && value !== null) {
            if (this._multiple) {
                parsedValue = (value as any[]).map((t) => '' + t);
            } else {
                parsedValue = '' + value;
            }
        }
        this._valueSubject.next(parsedValue);
    }

    public get valueObservable(): Observable<any> {
        return this._valueSubject.asObservable();
    }

    public get name(): string {
        return this._name;
    }

    public get type(): string {
        return this._type;
    }

    get options(): FormModelFieldOptions {
        return this._options;
    }

    public get selectableOptions(): FormModelFieldOption[] {
        const result: FormModelFieldOption[] = [];
        for (const key in this._options) {
            if (this._options.hasOwnProperty(key) && !this._disabledOptions.includes(key)) {
                result.push(this._options[key]);
            }
        }
        return result;
    }

    public get validationRules(): ValidationRule[] {
        return this._validationRules;
    }

    public get isMultiple(): boolean {
        return this._multiple;
    }

    public get isFilterable(): boolean {
        return this._filterable;
    }

    public get isRequired(): boolean {
        return this._validationRules.some((rule) => rule.rule === 'NotNull' || rule.rule === 'NotBlank');
    }

    public get minValue(): number | null {
        const min = this._validationRules.find((rule) => rule.rule === 'Min');
        return min ? min.value : null;
    }

    public get maxValue(): number | null {
        const max = this._validationRules.find((rule) => rule.rule === 'Max');
        return max ? max.value : null;
    }

    public get validState(): boolean | null {
        return this._validState;
    }

    public get ruleViolations(): ValidationRule[] {
        return this._ruleViolations;
    }

    public get visible(): boolean {
        return this._visible;
    }

    public set visible(value: boolean) {
        this._visible = value;
    }
}

interface FormModelFieldsDictionary {
    [key: string]: FormModelField;
}

export class FormModelGroup implements FormModelLifecycleAware {
    private _fields: FormModelFieldsDictionary = {};

    constructor(private _name: string) {
        //
    }

    public onFormModelCreated(): void {
        for (const key in this._fields) {
            if (this._fields.hasOwnProperty(key)) {
                this._fields[key].onFormModelCreated();
            }
        }
    }

    public addField(field: FormModelField): void {
        this._fields[field.name] = field;
    }

    public processValidationErrorResponse(validationErrorResponse: ValidationErrorResponse): void {
        for (const violation of validationErrorResponse.violations) {
            let fieldName = violation.field;
            const bracketPos = fieldName.indexOf('[');
            if (bracketPos !== -1) {
                fieldName = fieldName.substring(0, bracketPos);
            }
            const fieldData = this._fields[fieldName];
            if (fieldData) {
                fieldData.reportRuleViolation(violation);
            }
        }
    }

    public clearValidationState(): void {
        for (const key in this._fields) {
            if (this._fields.hasOwnProperty(key)) {
                this._fields[key].clearValidationState();
            }
        }
    }

    public hydrateValues(data: any): void {
        for (const key in data) {
            if (data.hasOwnProperty(key) && this._fields.hasOwnProperty(key)) {
                this._fields[key].value = data[key];
            }
        }
    }

    public resetValues(): void {
        for (const key in this._fields) {
            if (this._fields.hasOwnProperty(key)) {
                this._fields[key].resetValue();
            }
        }
    }

    public get name(): string {
        return this._name;
    }

    public get fields(): FormModelField[] {
        return Object.values(this._fields);
    }

    public get visibleFields(): FormModelField[] {
        return this.fields.filter((t) => t.visible);
    }
}

interface FormModelGroupsDictionary {
    [key: string]: FormModelGroup;
}

export default class FormModel {
    private _id: any = null;
    private _groups: FormModelGroupsDictionary = {};

    constructor(fields: any[]) {
        for (const field of fields) {
            const fieldData = new FormModelField(
                field.field,
                field.type,
                field.options,
                field.multiple,
                field.filterable,
                field.validationRules,
                field.interactions.map((t: any) => new FormFieldInteraction(t, this)),
                field.editableOnUpdate,
                field.disabled,
                field.embedActions,
            );
            if (!this._groups.hasOwnProperty(field.group)) {
                this._groups[field.group] = new FormModelGroup(field.group);
            }
            this._groups[field.group].addField(fieldData);
        }
        this.onFormModelCreated();
    }

    private onFormModelCreated(): void {
        for (const key in this._groups) {
            if (this._groups.hasOwnProperty(key)) {
                this._groups[key].onFormModelCreated();
            }
        }
    }

    public processValidationErrorResponse(validationErrorResponse: ValidationErrorResponse): void {
        for (const key in this._groups) {
            if (this._groups.hasOwnProperty(key)) {
                this._groups[key].processValidationErrorResponse(validationErrorResponse);
            }
        }
    }

    public clearValidationState(): void {
        for (const key in this._groups) {
            if (this._groups.hasOwnProperty(key)) {
                this._groups[key].clearValidationState();
            }
        }
    }

    public withHydratedValues(data: any): FormModel {
        if (data !== null) {
            if (data.hasOwnProperty('id')) {
                this._id = data.id;
            }
            for (const key in this._groups) {
                if (this._groups.hasOwnProperty(key)) {
                    this._groups[key].hydrateValues(data);
                }
            }
        } else {
            this.withResetValues();
        }
        return this;
    }

    public withResetValues(): FormModel {
        this._id = null;
        for (const key in this._groups) {
            if (this._groups.hasOwnProperty(key)) {
                this._groups[key].resetValues();
            }
        }
        return this;
    }

    public extractData<T>(): T {
        const result: any = { id: this._id };
        for (const groupKey in this._groups) {
            if (this._groups.hasOwnProperty(groupKey)) {
                // TODO: fields or visibleFields?
                for (const field of this._groups[groupKey].visibleFields) {
                    if (!field.isRequired && field.value === '') {
                        field.value = null;
                    } else if (
                        !field.isRequired &&
                        field.type === 'UnitDependableValue' &&
                        (field.value.value === '' || field.value.value === null)
                    ) {
                        field.value = { value: null, unit: null };
                    }
                    result[field.name] = field.value;
                }
            }
        }
        return <T>result;
    }

    public get groups(): FormModelGroup[] {
        return Object.values(this._groups);
    }

    public get isNew(): boolean {
        return this._id === null;
    }

    public get id(): number {
        return this._id;
    }

    public get isSavable(): boolean {
        if (this.isNew) {
            return true;
        }
        for (const groupKey in this._groups) {
            if (this._groups.hasOwnProperty(groupKey)) {
                for (const field of this._groups[groupKey].visibleFields) {
                    if (field.editableOnUpdate && !field.isDisabled) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
