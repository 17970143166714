
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import ToolbarInnerScaffold from '@/components/scaffolds/ToolbarInnerScaffold.vue';
import BaseCrudRepository from '@/repositories/BaseCrudRepository';
import FormModel from '@/repositories/data/FormModel';
import { BehaviorSubject, combineLatest, from, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import Resource from '@/repositories/Resource';
import EmbeddedForm from '@/util/form/EmbeddedForm';
import GlobalState from '@/util/GlobalState';
import ValidationErrorResponse from '@/repositories/data/ValidationErrorResponse';

@Component({
    components: {
        ToolbarInnerScaffold,
    },
})
export default class FormModelDisplay extends Vue {
    @Inject()
    private readonly globalState!: GlobalState;

    @Prop({ type: Object, required: true })
    private readonly entityRepository!: BaseCrudRepository<any, any, any>;

    @Prop({ type: String, required: true })
    private readonly i18nGroup!: string;

    @Prop({ type: Array, required: false, default: () => [] })
    private readonly embeds!: EmbeddedForm[];

    @Prop({
        type: String,
        required: false,
        default: 'id',
        validator(value: any): boolean {
            return value === 'id' || value === 'new';
        },
    })
    private readonly redirectMode!: string;

    private loading = true;
    private formModel = new FormModel([]);
    private displayError = false;
    private routeIdSubject = new BehaviorSubject<string>('new');
    private saveSubscription = new Subscription();
    private introspectionSubscription = new Subscription();

    public created(): void {
        this.onRouteChanged();
        this.introspectionSubscription = this.routeIdSubject
            .pipe(
                map((id) => (id === 'new' ? 0 : parseInt(id, 10))),
                switchMap((id) =>
                    combineLatest([
                        this.entityRepository.introspect(id === 0, id),
                        id === 0 ? from([Resource.success(null)]) : this.entityRepository.findOne(id),
                    ]),
                ),
                map(([formModelResource, entityResource]) =>
                    formModelResource.merge(entityResource, (formModel, entity) =>
                        formModel !== null ? formModel.withHydratedValues(entity) : null,
                    ),
                ),
            )
            .subscribe((formModelResource) => {
                if (formModelResource.isSuccess && formModelResource.data) {
                    this.formModel = formModelResource.data;
                }
                this.loading = formModelResource.isLoading;
                this.displayError = formModelResource.isError;
                if (formModelResource.isError) {
                    this.globalState.generalErrorHandling(formModelResource.error);
                }
            });
    }

    public beforeDestroy(): void {
        this.saveSubscription.unsubscribe();
        this.introspectionSubscription.unsubscribe();
    }

    public onSubmit(): void {
        const model = this.formModel.extractData();
        const isNew = this.isNew;
        this.formModel.clearValidationState();
        this.saveSubscription.unsubscribe();
        this.saveSubscription = (
            isNew ? this.entityRepository.create(model) : this.entityRepository.update(model)
        ).subscribe((resource) => {
            if (resource.isSuccess) {
                this.globalState.toast(
                    this.$t('crud.' + this.i18nGroup + (isNew ? '.ui.created' : '.ui.updated')).toString(),
                );
                if (isNew && this.redirectMode === 'id') {
                    this.$router.push({ name: this.$route.name!, params: { id: resource.data.id } });
                } else if (isNew && this.redirectMode === 'new') {
                    this.routeIdSubject.next('new');
                }
            } else if (resource.isError) {
                let validationError = false;
                const error = resource.error;
                if (
                    error.response &&
                    error.response.status === 400 &&
                    error.response.data &&
                    error.response.data.data &&
                    error.response.data.data.validationError
                ) {
                    const validationErrorResponse: ValidationErrorResponse = error.response.data.data;
                    validationError = true;
                    this.formModel.processValidationErrorResponse(validationErrorResponse);
                }
                if (validationError) {
                    this.globalState.toast(this.$t('general.validationError').toString());
                } else {
                    this.displayError = true;
                }
            }
        });
    }

    @Watch('$route')
    public onRouteChanged(): void {
        this.routeIdSubject.next(this.$route.params.hasOwnProperty('id') ? this.$route.params.id : 'new');
    }

    public get isNew(): boolean {
        return this.formModel.isNew;
    }
}
