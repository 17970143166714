
import { Component, Inject, Vue } from 'vue-property-decorator';
import GeneralScaffold from '@/components/scaffolds/GeneralScaffold.vue';
import FormModelDisplay from '@/components/form/FormModelDisplay.vue';
import SubscriptionMassRepository from '@/repositories/SubscriptionMassRepository';

@Component({
    components: {
        GeneralScaffold,
        FormModelDisplay,
    },
})
export default class SubscriptionMassFormScreen extends Vue {
    @Inject()
    public readonly subscriptionMassRepository!: SubscriptionMassRepository;
}
