import ValidationErrorResponse from '@/repositories/data/ValidationErrorResponse';
import VueI18n, { IVueI18n } from 'vue-i18n';

export default class SimpleFormValidationHandler {
    private $i18n: (VueI18n & IVueI18n) | null = null;
    private keys: string[];
    private validationErrors: { [key: string]: string[] } = {};

    public constructor(keys: string[]) {
        this.keys = keys;
        this.clearValidation();
    }

    public handleErrorResponse(error: any): boolean {
        let validationError = false;
        if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.validationError
        ) {
            const validationErrorResponse: ValidationErrorResponse = error.response.data.data;
            validationError = true;
            this.processValidationErrorResponse(validationErrorResponse);
        }
        return validationError;
    }

    public clearValidation(): void {
        for (const key of this.keys) {
            this.validationErrors[key] = [];
        }
    }

    private processValidationErrorResponse(validationErrorResponse: ValidationErrorResponse): void {
        if (this.$i18n != null) {
            for (const violation of validationErrorResponse.violations) {
                if (this.validationErrors.hasOwnProperty(violation.field)) {
                    if (violation.hasOwnProperty('value')) {
                        this.validationErrors[violation.field].push(
                            this.$i18n.t('validation.' + violation.rule, { value: violation.value }).toString(),
                        );
                    } else {
                        this.validationErrors[violation.field].push(
                            this.$i18n.t('validation.' + violation.rule).toString(),
                        );
                    }
                }
            }
        }
    }

    public get errors(): { [key: string]: string[] } {
        return this.validationErrors;
    }

    public set translator($i18n: VueI18n & IVueI18n) {
        this.$i18n = $i18n;
    }
}
