import Vue from 'vue';
import VueRouter, { NavigationGuard } from 'vue-router';
import HomeScreen from '@/components/screens/HomeScreen.vue';
import OrganizationListScreen from '@/components/screens/OrganizationListScreen.vue';
import OrganizationFormScreen from '@/components/screens/OrganizationFormScreen.vue';
import { authManager } from '@/container';
import LoginScreen from '@/components/screens/LoginScreen.vue';
import Login2FaScreen from '@/components/screens/Login2FaScreen.vue';
import SignupScreen from '@/components/screens/SignupScreen.vue';
import VerifyScreen from '@/components/screens/VerifyScreen.vue';
import ForgotPasswordScreen from '@/components/screens/ForgotPasswordScreen.vue';
import UserListScreen from '@/components/screens/UserListScreen.vue';
import UserFormScreen from '@/components/screens/UserFormScreen.vue';
import SubscriptionListScreen from '@/components/screens/SubscriptionListScreen.vue';
import SubscriptionFormScreen from '@/components/screens/SubscriptionFormScreen.vue';
import InvitationAcceptScreen from '@/components/screens/InvitationAcceptScreen.vue';
import SubscriptionMassFormScreen from '@/components/screens/SubscriptionMassFormScreen.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeScreen,
        meta: {
            navGroup: 'home',
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: LoginScreen,
        meta: {
            navGroup: 'login',
            requiresAuth: false,
        },
    },
    {
        path: '/two-factor',
        name: 'login2fa',
        component: Login2FaScreen,
        meta: {
            navGroup: 'login',
            requiresAuth: false,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: LoginScreen,
        meta: {
            navGroup: 'login',
            requiresAuth: false,
        },
    },
    {
        path: '/signup',
        name: 'signup',
        component: SignupScreen,
        meta: {
            navGroup: 'login',
            requiresAuth: false,
        },
    },
    {
        path: '/signup/verify/:code',
        name: 'verify',
        component: VerifyScreen,
        meta: {
            navGroup: 'login',
            requiresAuth: false,
        },
    },
    {
        path: '/recover-account',
        name: 'recovery',
        component: ForgotPasswordScreen,
        meta: {
            navGroup: 'login',
            requiresAuth: false,
        },
    },
    {
        path: '/recover-account/:code',
        name: 'recoveryCode',
        component: ForgotPasswordScreen,
        meta: {
            navGroup: 'login',
            requiresAuth: false,
        },
    },
    {
        path: '/user',
        name: 'userList',
        component: UserListScreen,
        meta: {
            navGroup: 'user',
            requiresAuth: true,
        },
    },
    {
        path: '/user/:id',
        name: 'userForm',
        component: UserFormScreen,
        meta: {
            navGroup: 'user',
            requiresAuth: true,
        },
    },
    {
        path: '/organization',
        name: 'organizationList',
        component: OrganizationListScreen,
        meta: {
            navGroup: 'organization',
            requiresAuth: true,
        },
    },
    {
        path: '/organization/:id',
        name: 'organizationForm',
        component: OrganizationFormScreen,
        meta: {
            navGroup: 'organization',
            requiresAuth: true,
        },
    },
    {
        path: '/subscription',
        name: 'subscriptionList',
        component: SubscriptionListScreen,
        meta: {
            navGroup: 'subscription',
            requiresAuth: true,
        },
    },
    {
        path: '/subscription/:id',
        name: 'subscriptionForm',
        component: SubscriptionFormScreen,
        meta: {
            navGroup: 'subscription',
            requiresAuth: true,
        },
    },
    {
        path: '/subscription-mass/:id',
        name: 'subscriptionMassForm',
        component: SubscriptionMassFormScreen,
        meta: {
            navGroup: 'subscription',
            requiresAuth: true,
        },
    },
    {
        path: '/invitations',
        name: 'invitationAcceptForm',
        component: InvitationAcceptScreen,
        meta: {
            navGroup: 'organizationInvite',
            requiresAuth: false,
        },
    },
];

const authGuard: NavigationGuard = (to, from, next) => {
    if ((!authManager.token || authManager.expired) && to.meta && to.meta.requiresAuth) {
        authManager.startRedirectLogin();
    } else {
        next();
    }
};

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(authGuard);

export default router;
