
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { OnBeforeDestroy, OnCreated } from '@/util/vue/vueCallbacks';
import LoginScaffold from '@/components/scaffolds/LoginScaffold.vue';
import AuthRepository from '@/repositories/AuthRepository';
import { Subscription } from 'rxjs';

@Component({
    components: { LoginScaffold },
})
export default class VerifyScreen extends Vue implements OnCreated, OnBeforeDestroy {
    public loading = true;

    @Inject() private readonly authRepository!: AuthRepository;
    private authSubscription = new Subscription();

    public created(): void {
        this.handleCode();
    }

    public beforeDestroy(): void {
        this.authSubscription.unsubscribe();
    }

    @Watch('$route')
    public onRouteChanged(): void {
        this.handleCode();
    }

    private handleCode(): void {
        this.loading = true;
        const code = this.$route.params.code as string;
        if (code) {
            this.authSubscription = this.authRepository
                .verify(code)
                .subscribe((resource) => (this.loading = resource.isLoading));
        }
    }
}
