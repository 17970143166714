import Vue from 'vue';
// @ts-ignore
import Vuetify from 'vuetify/lib';
import i18n from './i18n';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#00549a',
                secondary: '#424242',
                accent: '#f6a43e',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
    },
    lang: {
        t: (key: any, ...params: any[]) => i18n.t(key, params),
    },
    icons: {
        iconfont: 'mdi',
    },
});
