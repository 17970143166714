import { from, Observable } from 'rxjs';
import Resource from '@/repositories/Resource';
import { catchError, map, startWith } from 'rxjs/operators';

export default function networkBoundResource<T>(observable: Observable<T | null>): Observable<Resource<T>> {
    return observable.pipe(
        map((t) => Resource.success(t)),
        catchError((t) => from([Resource.error<T>(t, null)])),
        startWith(Resource.loading<T>(null)),
    );
}
