
import { Component, Inject, Vue } from 'vue-property-decorator';
import GeneralScaffold from '@/components/scaffolds/GeneralScaffold.vue';
import PagedDataTable from '@/components/PagedDataTable.vue';
import SubscriptionRepository from '@/repositories/SubscriptionRepository';

@Component({
    components: { GeneralScaffold, PagedDataTable },
})
export default class SubscriptionListScreen extends Vue {
    @Inject()
    public readonly subscriptionRepository!: SubscriptionRepository;

    private typeFilter = 'both';

    public get headers(): any[] {
        return [
            {
                text: this.$t('general.actions'),
                value: 'action',
                sortable: false,
            },
            {
                text: this.$t('crud.subscription.label'),
                align: 'left',
                value: 'label',
                sortable: true,
            },
            {
                text: this.$t('crud.subscription.beginTimestamp'),
                value: 'beginTimestamp',
                sortable: true,
            },
            {
                text: this.$t('crud.subscription.endTimestamp'),
                value: 'endTimestamp',
                sortable: true,
            },
            {
                text: this.$t('crud.subscription.consumerCount'),
                value: 'verboseConsumerCount',
                sortable: true,
            },
        ];
    }

    public onAddMass(): void {
        this.$router.push({ name: 'subscriptionMassForm', params: { id: 'new' } });
    }

    public get filterOptions() {
        return [
            { text: this.$t('crud.subscription.filters.both'), value: 'both' },
            { text: this.$t('crud.subscription.filters.organization'), value: 'organization' },
            { text: this.$t('crud.subscription.filters.user'), value: 'user' },
        ];
    }
}
